import React, { Component } from "react";
import { View, Text, Dimensions, Image, TouchableOpacity } from "react-native";
import Context from "../context";
import { connect } from "react-redux";
import { getClasses } from "../redux/actions/class";
import { Table, Button, Form, Row, Col, Modal, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./style.css";
import { createScore, getScores } from '../redux/actions/score'
import { icons } from '../icons'

const { height } = Dimensions.get("screen");

const monthNames = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık']

export class Score extends Component {
  state = {
    _class: null,
    lesson: null,
    scores: [],
    scaleModal: false,
    scaleModalContent: [],

  };

  componentDidMount() {
    this.props.getClasses();
  }

  create = () => {
    const { scores, _class } = this.state
    this.props.createScore({
      class: _class.id,
      scores
    }, (e) => {

      if (e)
        alert('Kaydedildi!')

    })
  }

  getItems = () => {

    const { _class, lesson } = this.state

    this.props.getScores(_class.id, lesson.id, (e) => {
      console.log('getItems', e)
      this.setState({ scores: e })
    })

  }

  geriyeDogruAylariListele = () => {
    const bugun = new Date(); // Bugünün tarihini al

    let ayListesi = []; // Ay listesi için boş bir dizi oluştur

    // Bugünün ay ve yılını al
    let ay = bugun.getMonth() + 1; // JavaScript'te aylar 0'dan başlar, bu yüzden +1 ekliyoruz
    let yil = bugun.getFullYear();

    // Eylül ayına (9. ay) kadar geriye doğru giderek ayları listele
    while (ay !== 9 || yil !== (bugun.getFullYear() - 1)) { // Eylül ayına ve bir önceki yıla kadar geriye git
      ayListesi.push({ ay: ay, yil: yil }); // Ay ve yılı ayListesi'ne ekle

      // Ayı azalt, eğer ocak ayına gelirsek yılı da azalt
      if (ay === 1) {
        ay = 12; // Aralık ayı
        yil -= 1; // Bir önceki yıl
      } else {
        ay -= 1; // Önceki ay
      }
    }

    // Son olarak, Eylül ayını da listeye ekle
    ayListesi.push({ ay: 9, yil: yil });

    return ayListesi;
  }

  ozelYuvarla=(sayi)=> {
    let altTamsayi = Math.floor(sayi); // Sayının alt tam kısmını al
  
    if (sayi - altTamsayi >= 0.5) {
      // Sayının ondalık kısmı 0.5'ten büyük veya eşitse, yukarı yuvarla
      return Math.ceil(sayi);
    } else {
      // Sayının ondalık kısmı 0.5'ten küçükse, aşağı yuvarla
      return altTamsayi;
    }
  }
  


  rowRender = (list, student, evaluation, month, scales = []) => {
    let copyScores = [...list]
    const { lesson, _class } = this.state
    const isItemIndex = copyScores.findIndex((itemScore) => itemScore.student.id === student.id && itemScore.evaluation.id === evaluation.id && itemScore.month === month)
    let copyScalesList = [...scales]

    const currentMonth = new Date().getMonth()

    return (
      <select disabled={currentMonth !== month ? true : false} className="form-select" value={isItemIndex > -1 ? copyScores[isItemIndex].score : -1} onChange={(e) => {

        if (isItemIndex > -1) {
          let isItem = copyScores[isItemIndex]
          isItem.score = Number.parseInt(e.target.value)
          copyScores.splice(isItemIndex, 1, isItem)
        } else {
          const newItem = {
            student,
            month,
            score: Number.parseInt(e.target.value),
            lesson,
            class: _class,
            evaluation: evaluation
          }
          copyScores.push(newItem)
        }

        this.setState({ scores: copyScores }, () => console.log('state', this.state.scores))

      }}>
        <option value={-1}>Boş</option>
        {
          copyScalesList.sort((a, b) => b.score - a.score).map((scale) => {
            return (
              <option value={scale.score}>{scale.score + ' - ' + scale.name}</option>
            )
          })
        }
      </select>
    )



    // return (
    //   <Form.Control
    //     type="number"
    //     placeholder="Puan"
    //     onChange={(e) => {


    //       if (isItemIndex > -1) {
    //         let isItem = copyScores[isItemIndex]
    //         isItem.score = Number.parseInt(e.target.value)
    //         copyScores.splice(isItemIndex, 1, isItem)
    //       } else {
    //         const newItem = {
    //           student,
    //           month,
    //           score: Number.parseInt(e.target.value),
    //           lesson,
    //           class: _class,
    //           evaluation: evaluation
    //         }
    //         copyScores.push(newItem)
    //       }

    //       this.setState({ scores: copyScores }, () => console.log('state', this.state.scores))

    //     }}
    //     className="form-control form-control-sm"
    //     style={{ width: 75 }}
    //     max={10}
    //     min={1}
    //     value={isItemIndex>-1?copyScores[isItemIndex].score:0}
    //   />
    // )
  }

  render() {
    const { _class, lesson, scores, scaleModalContent } = this.state;
    const { classes } = this.props;

    return (
      <Context
        title="Sınıflar"
        context={
          <View style={{ minHeight: height }}>
            <div className="container mt-5">
              <Form>
                <Row>
                  <Col xs={12} md={12} className="mb-2">
                    <select
                      className="form-select"
                      value={_class?.id}
                      onChange={(e) => {
                        const copyItem = this.props.classes.find(
                          (item) => item.id === Number.parseInt(e.target.value)
                        );
                        this.setState({ _class: copyItem }, () =>
                          console.log("state", this.state)
                        );
                      }}
                      style={{ marginTop: 20, marginBottom: 20 }}
                    >
                      <option value="">Sınıf Seç</option>
                      {classes.map((item, index) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                    </select>
                  </Col>
                </Row>
                {_class ? (
                  <Row>
                    <Col xs={12} md={12} className="mb-2">
                      <select
                        className="form-select"
                        value={lesson?.id}
                        onChange={(e) => {
                          const copyItem = _class.lessons.find(
                            (item) =>
                              item.id === Number.parseInt(e.target.value)
                          );
                          this.setState({ lesson: copyItem }, () =>
                            this.getItems()
                          );
                        }}
                        style={{ marginTop: 20, marginBottom: 20 }}
                      >
                        <option value="">Ders Seç</option>
                        {_class.lessons.map((item, index) => {
                          return (
                            <option value={item.id}>
                              {item?.lesson?.name}
                            </option>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>
                ) : null}
              </Form>

              <View
                style={{
                  marginTop: 20,
                }}
              >
                <h5>Değerlendirmeler</h5>

                {lesson
                  ? lesson?.lesson?.evaluations.map((evaluationItem, index) => {
                    return (
                      <Col xs={12} md={12} className="mb-2">
                        <Table striped bordered hover className="mt-3">
                          <tbody>
                            <tr>
                              <td colSpan={12}>
                                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                  <p style={{ flex: 1 }}>{evaluationItem.evaluation.name}</p>
                                  <TouchableOpacity style={{ marginRight: 20 }} onPress={() => {
                                    this.setState({ scaleModal: true, scaleModalContent: [...evaluationItem.evaluation.scales] })
                                  }}>
                                    <Image source={icons.ScaleList} style={{ width: 30, height: 30 }} />
                                  </TouchableOpacity>
                                </View>
                              </td>
                            </tr>
                            <tr>
                              <td>Öğrenciler</td>
                              {
                                this.geriyeDogruAylariListele().map((month) => {
                                  return (
                                    <td>{monthNames[month.ay - 1]}</td>
                                  )
                                })
                              }
                              <td>Ortalama</td>
                            </tr>
                            {_class.students.map((student) => {

                              let totalScore = 0
                              console.log('totalFilter', scores.filter((totalFilter) => totalFilter.student.id === student.id && totalFilter.evaluation.id === evaluationItem.id))
                              scores.filter((totalFilter) => totalFilter.student.id === student.id && totalFilter.evaluation.id === evaluationItem.id).map((score) => {
                                console.log('totalFilterItem', score)
                                totalScore += score.score
                              })

                              totalScore = Number.parseFloat((totalScore).toFixed(2))

                              return (
                                <tr>
                                  <td>
                                    {student.first_name +
                                      " " +
                                      student.last_name}
                                  </td>
                                  {
                                    this.geriyeDogruAylariListele().map((month) => {
                                      return <td>{this.rowRender(scores, student, evaluationItem, month.ay - 1, evaluationItem.evaluation.scales)}</td>
                                    })
                                  }

                                  <td>
                                    <p>
                                      {
                                        evaluationItem.evaluation.scales.find((scale) => scale.score === this.ozelYuvarla((totalScore / scores.filter((score) => score.student.id === student.id && score.evaluation.id === evaluationItem.id).length > 0 ? totalScore / scores.filter((score) => score.student.id === student.id && score.evaluation.id === evaluationItem.id).length : totalScore)))?.name

                                      }
                                    </p>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Col>
                    );
                  })
                  : null}
              </View>

              <Col xs={12} md={12} className="mb-2">
                <Button className="w-100" onClick={() => { this.create() }}>
                  Kaydet
                </Button>
              </Col>
            </div>

            <Modal className="modal-lg" show={this.state.scaleModal} onHide={() => this.setState({ scaleModal: false, scaleModalContent: [], })}>
              <Modal.Header closeButton>
                <Modal.Title>Değerlendirme Skalaları</Modal.Title>
              </Modal.Header>
              <Modal.Body>

                <Table striped bordered hover className="mt-3">
                  <thead>
                    <tr>
                      <th>Skala Adı</th>
                      <th>Açıklaması</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      scaleModalContent.map((scaleItem) => {
                        return (
                          <tr>
                            <td style={{ flex: 1 }}>{scaleItem.name}</td>
                            <td style={{ flex: 2 }}>{scaleItem.desc}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </Table>

              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => this.setState({ scaleModal: false, scaleModalContent: null })}>Kapat</Button>
              </Modal.Footer>
            </Modal>
          </View>
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  classes: state.Classes.classes,
});

const mapDispatchToProps = {
  getClasses,
  createScore,
  getScores
};

export default connect(mapStateToProps, mapDispatchToProps)(Score);

import {combineReducers} from 'redux'

import UserReducer from './userReducer'
import EvaluationsReducer from './evaluations'
import LessonsReducer from './lessons'
import StudentReducer from './students'
import ClassReducer from './classes'
import UsersReducer from './users'
import JobsReducer from './jobs'
import TeachersReducer from './teacher'
import AbilityReducer from './teacher'

export default combineReducers({
    User:UserReducer,
    Evaluations:EvaluationsReducer,
    Lessons:LessonsReducer,
    Students:StudentReducer,
    Classes:ClassReducer,
    Users:UsersReducer,
    Jobs:JobsReducer,
    Teachers:TeachersReducer,
    Ability:AbilityReducer,
})
import React, { Component } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux'
import {login} from '../redux/actions/auth'

export class LoginPage extends Component {

  state={
    mail:'',
    password:''
  }

  render() {
    const {mail,password} = this.state
    return (
      <div className="d-flex justify-content-center align-items-center w-100" style={{ minHeight: '100vh' }}>
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={6}>
              <div className="rounded p-4 border border-secondary">
                <h2 className="mb-4">Giriş Yap</h2>
                  <Form.Group controlId="formBasicEmail" className='mb-3'>
                    <Form.Label>Email adresi</Form.Label>
                    <Form.Control value={mail} type="email" placeholder="Email adresinizi girin" onChange={(e)=>this.setState({mail:e.target.value})} />
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword" className='mb-3'>
                    <Form.Label>Şifre</Form.Label>
                    <Form.Control value={password} type="password" placeholder="Şifrenizi girin" onChange={(e)=>this.setState({password:e.target.value})}/>
                  </Form.Group>

                  <Button variant="primary" type="submit" onClick={()=>{
                    this.props.login(mail,password,(e)=>{
                      if(!e)
                        alert('Mail adresi veya Şifre yanlış!')
                    })
                  }}>
                    Giriş Yap
                  </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {
  login
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
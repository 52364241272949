import axios from 'axios'
import { TEACHERS,CREATE_TEACHER,UPDATE_TEACHER,REMOVE_TEACHER } from './types'
import { MAIN_URL, tokenConfig,tokenConfigMultiPart } from './host'

export const getTeachers = (e) => (dispatch, getState) => {

    let config = tokenConfig(getState)


    axios.get(MAIN_URL + '/teachers/', config)
        .then(res => {
            dispatch({
                type: TEACHERS,
                payload: res.data
            })
        })
}

export const getTeacher = (id,e) => (dispatch, getState) => {

    let config = tokenConfig(getState)


    axios.get(MAIN_URL + '/teachers/'+id+'/', config)
        .then(res => {
            e(res.data)
        })
}

export const createTeacher = (data,e) => (dispatch, getState) => {

    let config=tokenConfigMultiPart(getState)

    axios.post(MAIN_URL + '/teachers/',data, config)
        .then(res => {
            dispatch({
                type: CREATE_TEACHER,
                payload: res.data
            })

            e(res.data)
        })
}


export const updateTeacher = (id,data,e) => (dispatch, getState) => {

    let config=tokenConfigMultiPart(getState)


    axios.put(MAIN_URL + '/teachers/'+id+'/',data, config)
        .then(res => {
            dispatch({
                type: UPDATE_TEACHER,
                payload: res.data
            })

            e(res.data)
        })
}

export const removeTeachers = (id,e) => (dispatch, getState) => {

    let config = tokenConfig(getState)


    axios.delete(MAIN_URL + '/teachers/'+id+'/', config)
        .then(res => {
            dispatch({
                type: REMOVE_TEACHER,
                payload: id
            })

            e(res.data)
        })
}


export const getTeacherJob = (data,e) => (dispatch, getState) => {

    let config = tokenConfig(getState)


    axios.post(MAIN_URL + '/jobs/result/',data, config)
        .then(res => {
            e(res.data)
        })
}
import React, { Component } from 'react'
import { View, Text } from 'react-native'
import Context from '../context'
import { getScoreControl } from '../redux/actions/score'
import { connect } from 'react-redux'

export class Home extends Component {

  constructor(props) {
    super(props)
    if (props.user?.user_type === 4) {
      props.getScoreControl((e) => {
        if (e && e.length === 0) {
          alert('Lütfen bu ayın puanlamasını giriniz!')
        }
      })
    }
  }

  render() {
    return (
      <Context context={
        <Text>Test</Text>
      } />
    )
  }
}


const mapStateToProps = (state) => ({
  user: state.User.user,

})

const mapDispatchToProps = {
  getScoreControl
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
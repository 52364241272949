import axios from 'axios'
import { EVALUATIONS,CREATE_EVALUATION,UPDATE_EVALUATION,REMOVE_EVALUATION } from './types'
import { MAIN_URL, tokenConfig,tokenConfigMultiPart } from './host'

export const getEvaluations = (e) => (dispatch, getState) => {

    let config = tokenConfig(getState)


    axios.get(MAIN_URL + '/evaluations/', config)
        .then(res => {
            dispatch({
                type: EVALUATIONS,
                payload: res.data
            })
        })
}

export const getEvaluation = (id,e) => (dispatch, getState) => {

    let config = tokenConfig(getState)


    axios.get(MAIN_URL + '/evaluations/'+id+'/', config)
        .then(res => {
            e(res.data)
        })
}

export const createEvaluation = (data,e) => (dispatch, getState) => {

    let config=tokenConfigMultiPart(getState)

    axios.post(MAIN_URL + '/evaluations/',data, config)
        .then(res => {
            dispatch({
                type: CREATE_EVALUATION,
                payload: res.data
            })

            e(res.data)
        })
}


export const updateEvaluation = (id,data,e) => (dispatch, getState) => {

    let config=tokenConfig(getState)


    axios.put(MAIN_URL + '/evaluations/'+id+'/',data, config)
        .then(res => {
            dispatch({
                type: UPDATE_EVALUATION,
                payload: res.data
            })

            e(res.data)
        })
}

export const removeEvaluations = (id,e) => (dispatch, getState) => {

    let config = tokenConfig(getState)


    axios.delete(MAIN_URL + '/evaluations/'+id+'/', config)
        .then(res => {
            dispatch({
                type: REMOVE_EVALUATION,
                payload: id
            })

            e(res.data)
        })
}

import React, { Component } from 'react'
import {View,Text} from 'react-native'
import TopMenu from './Menu/top'
import SideMenu from './Menu/side'
import Footer from './Footer'
import { connect } from 'react-redux'

 class Context extends Component {
  render() {


    return (
      <View style={{width:'100%'}}>
        <TopMenu />
        <View style={{flexDirection:'row'}}>
            <View>
                <SideMenu hide={this.props.user && this.props.user.user_type===4?true:false} />
            </View>
            <View style={{flex:1,borderLeftWidth:1,borderLeftColor:'rgba(0,0,0,0.1)'}}>
                {
                    this.props.title?
                    <View style={{flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
                      <Text style={{fontSize:25,fontWeight:'500',marginLeft:25,marginTop:50,flex:1}}>{this.props.title}</Text>
                      {this.props.right?this.props.right:null}
                    </View>
                    :null
                }
                {
                    this.props.context?this.props.context:null
                }
            </View>
        </View>
        <Footer />
      </View>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.User.user,
})

const mapDispatchToProps = {

}


export default connect(mapStateToProps, mapDispatchToProps)(Context);

export const LOADING='LOADING'
export const LOADED='LOADED'
export const APP_STATUS='APP_STATUS'
export const APP_STATE='APP_STATE'

export const USER_LOADING='USER_LOADING'
export const USER_LOADED='USER_LOADED'
export const AUTH_ERROR='AUTH_ERROR'
export const LOGIN_SUCCESS='LOGIN_SUCCESS'
export const LOGIN_FAIL='LOGIN_FAIL'
export const LOGOUT='LOGOUT'
export const ACCOUNT_UPDATE='ACCOUNT_UPDATE'
export const CSRF='CSRF'

export const EVALUATIONS='EVALUATIONS'
export const CREATE_EVALUATION='CREATE_EVALUATION'
export const UPDATE_EVALUATION='UPDATE_EVALUATION'
export const REMOVE_EVALUATION='REMOVE_EVALUATION'

export const LESSONS='LESSONS'
export const CREATE_LESSON='CREATE_LESSON'
export const UPDATE_LESSON='UPDATE_LESSON'
export const REMOVE_LESSON='REMOVE_LESSON'

export const STUDENTS='STUDENTS'
export const CREATE_STUDENT='CREATE_STUDENT'
export const UPDATE_STUDENT='UPDATE_STUDENT'
export const REMOVE_STUDENT='REMOVE_STUDENT'

export const CLASSES='CLASSES'
export const CREATE_CLASS='CREATE_CLASS'
export const UPDATE_CLASS='UPDATE_CLASS'
export const REMOVE_CLASS='REMOVE_CLASS'

export const USERS='USERS'
export const CREATE_USER='CREATE_USER'
export const UPDATE_USER='UPDATE_USER'
export const REMOVE_USER='REMOVE_USER'

export const JOBS='JOBS'
export const CREATE_JOB='CREATE_JOB'
export const UPDATE_JOB='UPDATE_JOB'
export const REMOVE_JOB='REMOVE_JOB'

export const TEACHERS='TEACHERS'
export const CREATE_TEACHER='CREATE_TEACHER'
export const UPDATE_TEACHER='UPDATE_TEACHER'
export const REMOVE_TEACHER='REMOVE_TEACHER'

export const ABILITIES='ABILITIES'
export const CREATE_ABILITY='CREATE_ABILITY'
export const UPDATE_ABILITY='UPDATE_ABILITY'
export const REMOVE_ABILITY='REMOVE_ABILITY'
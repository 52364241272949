import { LESSONS,CREATE_LESSON,UPDATE_LESSON,REMOVE_LESSON } from '../actions/types';

const initialState = {
    lessons: [],
};


export default function (state = initialState, action) {
    switch (action.type) {
        case LESSONS:
            return {
                ...state,
                lessons: action.payload,
            };
        case CREATE_LESSON:
            return {
                ...state,
                lessons: [...state.lessons, action.payload],
            };
        case UPDATE_LESSON:
            const updatedLesson = action.payload;
            const updatedLessons = state.lessons.map((lesson) =>
                lesson.id === updatedLesson.id ? updatedLesson : lesson
            );
            return {
                ...state,
                lessons: updatedLessons,
            };
        case REMOVE_LESSON:
            const removedLessonId = action.payload;
            const remainingLessons = state.lessons.filter(
                (lesson) => lesson.id !== removedLessonId
            );
            return {
                ...state,
                lessons: remainingLessons,
            };

        default:
            return state;
    }
}

import React from 'react';
import { Nav } from 'react-bootstrap';
import { Image, View } from 'react-native'
import { icons } from '../icons'
import './style.css'; // Eğer özel CSS kullanıyorsanız


function SideMenu({
  hide = false
}) {
  return (
    <Nav defaultActiveKey="/home" className="flex-column sidebar">
      <View style={{ alignItems: 'center', justifyContent: 'center', marginBottom: 20 }}>
        <Image source={icons.Logo} style={{ width: 100, height: 50 }} resizeMode='contain' />

      </View>
      <Nav.Link href="/home">Anasayfa</Nav.Link>

      {
        !hide ?
          <View>
            <Nav.Link href="/ability">Beceriler</Nav.Link>
            <Nav.Link href="/evaluation">Değerlendirmeler</Nav.Link>
            <Nav.Link href="/lesson">Dersler</Nav.Link>
            <Nav.Link href="/student">Öğrenciler</Nav.Link>
            <Nav.Link href="/teacher">Öğretmenler</Nav.Link>
            <Nav.Link href="/classes">Sınıflar</Nav.Link>
            <Nav.Link href="/job">Meslekler</Nav.Link>
          </View>:null
        }

      <Nav.Link href="/score">Puanlama</Nav.Link>

      {/* Diğer menü öğeleri */}
    </Nav>
  );
}

export default SideMenu;

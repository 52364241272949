import {AUTH_ERROR,USER_LOADED,USER_LOADING, LOGIN_FAIL,LOGIN_SUCCESS, LOGOUT, ACCOUNT_UPDATE, CSRF, APP_STATE} from '../actions/types'
import Cookies from 'js-cookie';

const getToken = async () => {
    try {
      const token = await AsyncStorage.getItem('token');
      return token
    } catch (err) {
      console.log(err);
      return null
    }
};

const saveToken = (token) => {
    Cookies.set('token', token, { expires: 7 });
};

const removeToken = () => {
    Cookies.remove('token');
};


const initialState={
    auth:{
        token:null,
        isAuth:false,
        isLoading:false
    },
    appState:false,
    user:null,
    csrf:null,
}

export default function(state=initialState,action){

    switch(action.type){
        case USER_LOADING:
            return{
                ...state,
                auth:{...state.auth,isLoading:true}
            }
        case USER_LOADED:
            return{
                ...state,
                auth:{...state.auth,isLoading:false,isAuth:true,token:action.payload.token},
                user:action.payload.user!==undefined?action.payload.user:action.payload
            }

        case ACCOUNT_UPDATE:
            return{
                ...state,
                user:action.payload.user
            }
        case CSRF:
            Cookies.set('csrf', action.payload, { expires: 7 });

            return{
                ...state,
                csrf:action.payload
            }
        case LOGIN_SUCCESS:
            saveToken(action.payload.token)
            return {
                ...state,
                auth:{token:action.payload.token,isAuth:true,isLoading:false},
                user:action.payload.user
            }
        case APP_STATE:
            return{
                ...state,
                appState:action.payload.state
            }
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT:
            removeToken()
            return{
                ...state,
                auth:{token:null,isAuth:false,isLoading:false},
                user:null
            }
        default:
            return state
    }

}
import { CLASSES,CREATE_CLASS,UPDATE_CLASS,REMOVE_CLASS } from '../actions/types';

const initialState = {
    classes: [],
};


export default function (state = initialState, action) {
    switch (action.type) {
        case CLASSES:
            return {
                ...state,
                classes: action.payload,
            };
        case CREATE_CLASS:
            return {
                ...state,
                classes: [...state.classes, action.payload],
            };
        case UPDATE_CLASS:
            const updatedEvaluation = action.payload;
            const updatedEvaluations = state.classes.map((_class) =>
                _class.id === updatedEvaluation.id ? updatedEvaluation : _class
            );
            return {
                ...state,
                classes: updatedEvaluations,
            };
        case REMOVE_CLASS:
            const removedEvaluationId = action.payload;
            const remainingEvaluations = state.classes.filter(
                (_class) => _class.id !== removedEvaluationId
            );
            return {
                ...state,
                classes: remainingEvaluations,
            };

        default:
            return state;
    }
}

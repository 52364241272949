import React, { useState } from 'react';
import { Table, Button, Form, Row, Col, Modal } from 'react-bootstrap';
import './style.css'

function EvaluationPage({
  data = [],
  create = () => { },
  update = () => { },
  remove = () => { },
}) {
  const [inputValue, setInputValue] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [scaleName, setScaleName] = useState('');
  const [scaleDesc, setScaleDesc] = useState('');
  const [scaleScore, setScaleScore] = useState(1);
  const [editModalShow, setEditModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [editingItem, setEditingItem] = useState({});
  const [deletingItemId, setDeletingItemId] = useState(null);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSubmit = () => {
    if (inputValue.length > 5) {
      create(inputValue);
      setInputValue('');
    } else {
      alert('Lütfen geçerli bir değer girin!');
    }
  };

  const handleEditClick = (item) => {
    setEditingItem(item);
    setEditModalShow(true);
  };

  const handleEditChange = (e) => {
    setEditingItem({ ...editingItem, name: e.target.value });
  };

  const saveEdit = () => {
    update(editingItem.id, editingItem.name,editingItem.scales);
    setEditModalShow(false);
  };

  const handleDeleteClick = (id) => {
    setDeletingItemId(id);
    setDeleteModalShow(true);
  };

  const confirmDelete = () => {
    remove(deletingItemId);
    setDeleteModalShow(false);
  };

  const changeScaleValue = (index,element, value, _type) => {

    let copyEditingItem = { ...editingItem }
    let copyScaleList = [...copyEditingItem.scales]

    if (_type === 'add') {

      copyScaleList.push({ name: scaleName, desc: scaleDesc, score: scaleScore, edit: 'add' })
      copyEditingItem.scales = copyScaleList

      setEditingItem(copyEditingItem)


    } else if(_type==='change'){
      let copyItem = {...copyScaleList[index]}
      copyItem[element]=value

      if(copyItem['edit'] && copyItem['edit']!=='add')
        copyItem['edit']='change'

      copyScaleList[index]=copyItem
      copyEditingItem.scales=copyScaleList
      setEditingItem(copyEditingItem)
    }else if(_type==='remove'){

      let copyItem = {...copyScaleList[index]}
      if(copyItem['edit'] && copyItem['edit']==='add'){
        copyScaleList.splice(index,1)
      }else{
        copyItem['edit']='change'
        copyItem[element]=value
        copyScaleList[index]=copyItem
      }

      copyEditingItem.scales=copyScaleList
      setEditingItem(copyEditingItem)

    }

  }

  const filteredData = data.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  console.log('changeItem', editingItem)

  return (
    <div className="container mt-5">
      <Form>
        <Row>
          <Col xs={12} md={8} className="mb-2">
            <Form.Control
              type="text"
              placeholder="Değerlendirme Adı"
              value={inputValue}
              onChange={handleInputChange}
            />
          </Col>
          <Col xs={12} md={4} className="mb-2">
            <Button className="w-100" onClick={handleSubmit}>Oluştur</Button>
          </Col>
        </Row>
      </Form>

      <Row className="mb-3" style={{ marginTop: 20 }}>
        <Col xs={12} className="text-right">
          <Form.Control
            type="text"
            placeholder="Arama..."
            onChange={handleSearchChange}
            className="search-input"
          />
        </Col>
      </Row>

      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Sıra</th>
            <th className="name-column">Değerlendirme Adı</th>
            <th>Düzenle</th>
            <th>Sil</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td className="name-column">{item.name}</td>
              <td>
                <Button variant="info" onClick={() => handleEditClick(item)}>Düzenle</Button>
              </td>
              <td>
                <Button variant="danger" onClick={() => handleDeleteClick(item.id)}>Sil</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={editModalShow} onHide={() => setEditModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Değerlendirme Adını Düzenle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            value={editingItem.name || ''}
            onChange={handleEditChange}
          />
          <h5 className='mt-2'>Skala Ekle</h5>
          <Row>
            <Col xs={12} md={4} className="mb-2 mt-2">
              <Form.Control
                type="text"
                placeholder="Skala Adı"
                value={scaleName}
                onChange={(e) => {
                  setScaleName(e.target.value)
                }}
              />
            </Col>
            <Col xs={12} md={5} className="mb-2 mt-2">
              <Form.Control
                type="text"
                placeholder="Skala Açıklama"
                value={scaleDesc}
                onChange={(e) => {
                  setScaleDesc(e.target.value)
                }}
              />
            </Col>
            <Col xs={12} md={3} className="mb-2 mt-2">
              <Form.Control
                type="number"
                placeholder="Puan"
                onChange={(e) => setScaleScore(Number.parseInt(e.target.value))}
                value={scaleScore}
                max={10}
                min={1}
              />
            </Col>

          </Row>
          <Row>
            <Col xs={12} md={12} className='mb-2 mt-2'>
              <Button className="w-100" onClick={() => {
                changeScaleValue(null,null, null, 'add')
              }}>Ekle</Button>
            </Col>
          </Row>
          <Row>
            <h6>Eklenen Değerlendirmeler</h6>
            <Table striped bordered hover className="mt-3">
              <thead>
                <tr>
                  <th>Skala Adı</th>
                  <th>Skala Açk</th>
                  <th>Puan</th>
                  <th>Sil</th>
                </tr>
              </thead>
              <tbody>
                {
                  editingItem?.scales?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{
                          
                          <Form.Control
                            placeholder="Ad"
                            onChange={(e) => {
                              
                              changeScaleValue(index,'name',e.target.value,'change')

                            }}
                            value={item.name}
                          />

                        }</td>
                        <td>{
                          
                          <Form.Control
                            placeholder="Açıklama"
                            onChange={(e) => {
                              
                              changeScaleValue(index,'desc',e.target.value,'change')

                            }}
                            value={item.desc}
                          />

                        }</td>
                        <td>{
                          <Form.Control
                            type="number"
                            placeholder="Puan"
                            onChange={(e) => {
                              
                              changeScaleValue(index,'score',e.target.value,'change')

                            }}
                            className="form-control form-control-sm"
                            style={{ width: 50 }}
                            value={item.score}
                            max={10}
                            min={1}
                          />
                        }</td>
                        <td>
                          <Button variant={(item.edit)?(item.edit==='remove'?'success':'danger'):'danger'} onClick={()=>{
                            changeScaleValue(index,'edit',(item.edit)?(item.edit==='remove'?'show':'remove'):'remove','remove')
                          }}>
                            {
                              (item.edit)?(item.edit==='remove'?'Geri Al':'Sil'):'Sil'
                            }
                          </Button>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setEditModalShow(false)}>İptal</Button>
          <Button variant="primary" onClick={saveEdit}>Kaydet</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={deleteModalShow} onHide={() => setDeleteModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Silmek İstediğinizden Emin Misiniz?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteModalShow(false)}>
            Hayır
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Evet
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EvaluationPage;

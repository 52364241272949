import React, { Component } from "react";
import { View, Text, Dimensions, Image, TouchableOpacity, ScrollView, } from "react-native";
import Context from "../context";
import { connect } from "react-redux";
import { getEvaluations } from "../redux/actions/evaluation";
import {
  getStudent,
  createStudent,
  updateStudent,
  removeStudents,
  getStudentJob,
} from "../redux/actions/student";
import { Table, Button, Form, Row, Col, Modal, Nav } from "react-bootstrap";
import { MAIN_URL, MEDIA_URL } from "../redux/actions/host";
import { useNavigate } from "react-router-dom";
import { getScoresStudent } from "../redux/actions/score";
import moment from 'moment'
import * as Progress from 'react-native-progress';
import Chart from 'react-apexcharts'
import { icons } from "../icons";
import PDFDetail from './pdfDetail'

const { height, width } = Dimensions.get("screen");

export class StudentDetail extends Component {
  state = {
    first_name: "",
    last_name: "",
    birth_day: "",
    no: "",
    image: "",
    removeModal: false,
    edit: false,
    loading: true,
    scores: [],
    jobs: [],
    scales: [],
    scaleNames: [],
    startTime: moment(new Date()),
    endTime: null,
    pdf:false,
    series: [{
      name: 'Değer',
      data: []
    }],
    options: {

      chart: {
        height: 500,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          columnWidth: '50%',
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 2
      },

      grid: {
        row: {
          colors: ['#fff', '#f2f2f2']
        }
      },
      xaxis: {
        labels: {
          show: true,
          rotate: -45,
          trim: true,
          rotateAlways: true
        },
        categories: [],
        tickPlacement: 'on'
      },

      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [50, 0, 100]
        },
      },
      title: {
        style: {
          fontSize: 5,
          color: 'red'
        }
      }
    },

  };

  getResultTest = () => {

    const data = [
      {
        "evaluation_id": 14,
        "average": 6.3
      },
      {
        "evaluation_id": 24,
        "average": 4.1
      },
      {
        "evaluation_id": 25,
        "average": 8.6
      },
      {
        "evaluation_id": 28,
        "average": 7.08
      },
      {
        "evaluation_id": 27,
        "average": 6.9
      },
      {
        "evaluation_id": 11,
        "average": 4.6
      },
      {
        "evaluation_id": 9,
        "average": 9
      },
      {
        "evaluation_id": 18,
        "average": 7.9
      },
      {
        "evaluation_id": 31,
        "average": 5.6
      },
      {
        "evaluation_id": 19,
        "average": 8
      },
      {
        "evaluation_id": 35,
        "average": 8.6
      },
      {
        "evaluation_id": 37,
        "average": 10
      }
    ]
    this.props.getStudentJob({ evaluations: data }, (e) => {
      this.setState({ jobs: e.jobs });
    });
  }

  ozelYuvarla=(sayi)=> {
    let altTamsayi = Math.floor(sayi); // Sayının alt tam kısmını al
  
    if (sayi - altTamsayi >= 0.5) {
      // Sayının ondalık kısmı 0.5'ten büyük veya eşitse, yukarı yuvarla
      return Math.ceil(sayi);
    } else {
      // Sayının ondalık kısmı 0.5'ten küçükse, aşağı yuvarla
      return altTamsayi;
    }
  }

  getResult = () => {
    const { scores } = this.state;

    let chartScoreList = []
    let chartNameList = []
    let scaleList = []

    scores.map((sco) => {
      scaleList.push(sco.scales.find((scale) => scale.score === this.ozelYuvarla(sco.totalAverage))?.name)
      chartScoreList.push(this.ozelYuvarla(sco.totalAverage))
      chartNameList.push(sco.name)
    })

    const tooltipType = {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        console.log('series', w)
        return '<div class="arrow_box" style="padding:10px;background-color:#e1e1e1">' +
          '<span style="font-size:13px;margin-bottom:10px;font-weight:bold">' + chartNameList[dataPointIndex] + '</span></br>' +
          '<span>' + scaleList[dataPointIndex] + '</span>' +
          '</div>'

      }
    }

    this.setState({ scales: scaleList,scaleNames:chartNameList, series: [{ data: chartScoreList }], options: { ...this.state.options, tooltip: tooltipType, xaxis: { ...this.state.options.xaxis, categories: chartNameList } } },()=>console.log('yeni state',this.state))

    let copyList = [];

    scores.map((item) => {
      copyList.push({
        evaluation_id: item.id,
        average: item.totalAverage,
      });
    });


    this.props.getStudentJob({ evaluations: copyList }, (e) => {
      this.setState({ jobs: e.jobs });
    });
  };

  componentDidMount() {
    const url = window.location.href;
    const id = url.substring(url.lastIndexOf("/") + 1);

    this.props.getStudent(id, (e) => {

      let copyObj = e;
      copyObj["loading"] = false;
      copyObj["id"] = id;

      this.setState(copyObj, () => {});
    });
    //this.getResultTest()

    this.props.getScoresStudent(id, (e) => {
      let scoresList = [];

      e.map((item) => {
        const newData = {
          name: item.evaluation.evaluation.name,
          id: item.evaluation.evaluation.id,
          degree: item.evaluation.degree,
          scales: item.evaluation.evaluation.scales,
          items: e.filter(
            (filt) =>
              filt.evaluation.evaluation.id === item.evaluation.evaluation.id
          ),
        };

        if (
          scoresList.findIndex(
            (score) => score.id === item.evaluation.evaluation.id
          ) === -1
        )
          scoresList.push(newData);
      });

      let newList = [];

      scoresList.map((item) => {
        let lessonList = [];

        item.items.map((less) => {
          let average = 0;
          let newHour = 1;
          let newData = {
            id: less.lessonClass.id,
            name: less.lessonClass.lesson.name,
            items: item.items.filter(
              (filt) => filt.lessonClass.id === less.lessonClass.id
            ),
            lessonHour: less.lessonClass.lessonHour,
          };

          newData.items.map((lessonScore) => {
            average += lessonScore.score;
            newHour =
              lessonScore.evaluation.degree * less.lessonClass.lessonHour;
          });

          average = (average / newData.items.length).toFixed(2);

          newData["average"] = Number.parseFloat(average);
          newData["newHour"] = Number.parseFloat(newHour);

          if (
            lessonList.findIndex(
              (newListIndex) => newListIndex.id === less.lessonClass.id
            ) === -1
          )
            lessonList.push(newData);
        });

        let totalHour = 0;
        let defaultTotalHour = 0;
        let average = 0;

        lessonList.map((lesson) => {
          totalHour += lesson.newHour;
          defaultTotalHour += lesson.lessonHour;
        });

        lessonList.map((lesson) => {
          average += lesson.newHour * lesson.average;
        });
        average = Number.parseFloat((average / totalHour).toFixed(2));

        newList.push({
          id: item.id,
          name: item.name,
          lessons: lessonList,
          totalHour: defaultTotalHour,
          totalAverage: average,
          degree: item.degree,
          scales: item.scales
        });
      });


      this.setState({ scores: newList, endTime: moment(new Date()) }, () => this.getResult());
    });
  }

  update = () => {
    const { id, first_name, last_name, no, image, birth_day } = this.state;

    var data = new FormData();
    data.append("first_name", first_name);
    data.append("last_name", last_name);
    data.append("no", no);
    data.append("birth_day", birth_day);

    if (typeof image !== "string") {

      data.append("image", image);
    }

    this.props.updateStudent(id, data, (e) => {
      this.setState(e);
    });
  };

  remove = () => {
    const { id } = this.state;
    this.props.removeStudents(id, (e) => {
      window.location.href = "/student";
    });
  };

  handleImageSelect = (e) => {
    const file = e.target.files[0];
    this.setState({ image: file });
  };

  render() {
    const {
      first_name,
      last_name,
      no,
      birth_day,
      image,
      removeModal,
      edit,
      pdf,
      jobs,
      scales,
      scaleNames
    } = this.state;
    return (
      <Context
        title="Öğrenci"
        right={
          <TouchableOpacity onPress={()=>this.setState({pdf:true})}>
            <Image source={icons.PDF} style={{width:50,height:50,marginRight:20}} />
          </TouchableOpacity>
        }
        context={
          <View style={{ minHeight: height }}>
            <PDFDetail student={{first_name,last_name,no}} show={pdf} cancel={()=>this.setState({pdf:false})} grapOption={this.state.options} grapSeries={this.state.series} jobs={jobs} scales={scales} scaleNames={scaleNames} />
            <div className="container mt-5">
              <Row className="justify-content-md-center">
                <Col md={3}>
                  <TouchableOpacity
                    onPress={() => this._imageFileDialog.click()}
                  >
                    <Image
                      source={
                        image
                          ? {
                            uri:
                              typeof image !== "string"
                                ? URL.createObjectURL(image)
                                : image.startsWith("http")
                                  ? image
                                  : MEDIA_URL + image,
                          }
                          : null
                      }
                      style={{
                        width: 150,
                        height: 150,
                        borderRadius: 75,
                        borderWidth: 1,
                        borderColor: "rgba(0,0,0,0.1)",
                      }}
                    />
                  </TouchableOpacity>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={this.handleImageSelect}
                    style={{ display: "none" }}
                    ref={(node) => (this._imageFileDialog = node)}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={6}>
                  <Form.Group controlId="formFirstName">
                    <Form.Label>İsim</Form.Label>
                    <Form.Control
                      type="text"
                      value={first_name}
                      placeholder="İsim giriniz"
                      onChange={(e) =>
                        this.setState({ first_name: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formLastName">
                    <Form.Label>Soyisim</Form.Label>
                    <Form.Control
                      type="text"
                      value={last_name}
                      placeholder="Soyisim giriniz"
                      onChange={(e) =>
                        this.setState({ last_name: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={6}>
                  <Form.Group controlId="formSchoolNumber">
                    <Form.Label>Okul No</Form.Label>
                    <Form.Control
                      type="text"
                      value={no}
                      placeholder="Okul numarası giriniz"
                      onChange={(e) => this.setState({ no: e.target.value })}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formDate">
                    <Form.Label>Tarih</Form.Label>
                    <Form.Control
                      type="date"
                      value={birth_day}
                      onChange={(e) =>
                        this.setState({ birth_day: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12} md={6} className="mb-2">
                  <Button className="w-100" onClick={() => this.update()}>
                    Kaydet
                  </Button>
                </Col>
                <Col xs={12} md={6} className="mb-2">
                  <Button
                    className="w-100"
                    variant="danger"
                    onClick={() => this.setState({ removeModal: true })}
                  >
                    Sil
                  </Button>
                </Col>
              </Row>

              <Row className="mt-3">
                <h5>Aldığı Değerler</h5>
              </Row>
            </div>

            <View style={{ width: '100%', alignItems: 'center', justifyContent: 'center' }}>
              <Chart options={this.state.options} series={this.state.series} type="bar" width={width * 0.8} height={500} />

            </View>

            {/* <Row className="mb-3">
              <ScrollView horizontal={true}>
                {scores.map((score) => {
                  return (
                    <View style={{ padding: 10 }}>
                      <Table striped bordered hover className="mt-3 mr-2" style={{ marginRight: 20 }}>
                        <tbody>
                          <tr>
                            <td colSpan={3}>
                              <p>
                                {score.name} (Katsayı : {score.degree})
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>Ders Adı</td>
                            <td>Ders Saati</td>
                            <td>Ortalama</td>
                          </tr>
                          {score.lessons.map((lesson) => {
                            return (
                              <tr>
                                <td>{lesson.name}</td>
                                <td>{lesson.lessonHour}</td>
                                <td>{lesson.average}</td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td>Toplam</td>
                            <td>{score.totalHour}</td>
                            <td>{score.totalAverage}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </View>
                  );
                })}
              </ScrollView>
            </Row> */}

            <Row className="mt-3" style={{ marginLeft: 20, marginRight: 20 }}>
              <h5>Meslek Önermeleri</h5>
            </Row>

            <Row className="mt-3" style={{ marginLeft: 20, marginRight: 20, marginBottom: 50 }}>
              <ScrollView horizontal={true}>
                {
                  jobs.map((job, index) => {
                    return (
                      <View style={{ width: 200, height: 230, backgroundColor: '#3498db', borderRadius: 25, marginRight: 25 }}>
                        <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                          <Progress.Circle size={130} progress={job.percentage / 100} color="#2ecc71" borderWidth={2} borderColor="rgba(255,255,255,0.3)" thickness={5} />
                          <View style={{ position: 'absolute', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                            <Text style={{ fontSize: 25, color: 'white', fontWeight: '700' }}>%{job.percentage}</Text>
                          </View>
                        </View>
                        <View style={{ height: 80, alignItems: 'center', justifyContent: 'center' }}>
                          <Text style={{ fontSize: 20, color: 'white' }}>{job.name}</Text>
                          
                        </View>
                      </View>
                    )
                  })
                }
              </ScrollView>
            </Row>

            <Modal
              show={removeModal}
              onHide={() => this.setState({ removeModal: false })}
            >
              <Modal.Header closeButton>
                <Modal.Title>Silmek İstediğinizden Emin Misiniz?</Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => this.setState({ removeModal: false })}
                >
                  Hayır
                </Button>
                <Button variant="danger" onClick={() => this.remove()}>
                  Evet
                </Button>
              </Modal.Footer>
            </Modal>
          </View>
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  students: state.Students.students,
});

const mapDispatchToProps = {
  getEvaluations,
  getStudent,
  createStudent,
  updateStudent,
  removeStudents,
  getScoresStudent,
  getStudentJob,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentDetail);

import axios from 'axios'
import { LESSONS,CREATE_LESSON,UPDATE_LESSON,REMOVE_LESSON } from './types'
import { MAIN_URL, tokenConfig,tokenConfigMultiPart } from './host'

export const getLessons = (e) => (dispatch, getState) => {

    let config = tokenConfig(getState)


    axios.get(MAIN_URL + '/lessons/', config)
        .then(res => {
            dispatch({
                type: LESSONS,
                payload: res.data
            })
            e(res.data)
        })
}

export const getLesson = (id,e) => (dispatch, getState) => {

    let config = tokenConfig(getState)


    axios.get(MAIN_URL + '/lessons/'+id+'/', config)
        .then(res => {
            e(res.data)
        })
}

export const createLesson = (data,e) => (dispatch, getState) => {

    let config=tokenConfigMultiPart(getState)

    axios.post(MAIN_URL + '/lessons/',data, config)
        .then(res => {
            dispatch({
                type: CREATE_LESSON,
                payload: res.data
            })

            e(res.data)
        })
}


export const updateLesson = (id,data,e) => (dispatch, getState) => {

    let config=tokenConfig(getState)


    axios.put(MAIN_URL + '/lessons/'+id+'/',data, config)
        .then(res => {
            dispatch({
                type: UPDATE_LESSON,
                payload: res.data
            })

            e(res.data)
        })
}

export const removeLesson = (id,e) => (dispatch, getState) => {

    let config = tokenConfig(getState)


    axios.delete(MAIN_URL + '/lessons/'+id+'/', config)
        .then(res => {
            dispatch({
                type: REMOVE_LESSON,
                payload: id
            })

            e(res.data)
        })
}

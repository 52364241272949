import React, { Component } from 'react'
import { View, Text, Dimensions, Image, TouchableOpacity } from 'react-native'
import Context from '../context'
import { connect } from 'react-redux'
import { getEvaluations } from '../redux/actions/evaluation'
import { getStudents, createStudent, updateStudent, removeStudents } from '../redux/actions/student'
import { Table, Button, Form, Row, Col, Modal, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const { height } = Dimensions.get('screen')

export class Student extends Component {

    state = {
        students: [],
        name: '',
        surname: '',
        bDay: '',
        image: null,
        no: '',
        search: '',
        removeModal: false,
        newModal: false,
        selectedItem: null,
        selectedEvaItem: null,
        degreeValue: 1,

    }

    componentDidMount() {
        this.props.getEvaluations()
        this.props.getStudents((e) => {
            this.setState({ students: e })
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.students !== this.props.students) {
            if (this.props.students !== this.state.students)
                this.setState({ students: this.props.students })
        }
    }

    create = () => {

        const { name, surname, image, bDay, no } = this.state

        var data = new FormData()
        data.append('first_name', name)
        data.append('last_name', surname)
        data.append('bDay', bDay)
        data.append('no', no)

        if (image) {
            data.append("image", {
                uri: URL.createObjectURL(image),
                type: 'image/jpeg',
                name: `student.jpg`
            });
        }


        this.props.createStudent(data, (e) => {
            console.log('oluşturma', e)
            alert('Öğrenci Oluşturuldu!')
            this.setState({ students: [...this.state.students, e], name: '', surname: '', no: '', bDay: '', image: null, newModal: false })
        })
    }

    update = (id, name) => {
        const { selectedItem } = this.state
        console.log('selectedItems', selectedItem.evaluations)
        this.props.updateStudent(selectedItem.id, {
            name: selectedItem.name,
            evaluations: selectedItem.evaluations
        }, (e) => {
            this.setState({ selectedItem: null, newModal: false, })
        })

    }

    remove = () => {
        const { selectedItem } = this.state
        this.props.removeStudents(selectedItem.id, (e) => {
            this.setState({ selectedItem: null, removeModal: false })
        })
    }

    handleImageSelect = (e) => {
        const file = e.target.files[0];
        this.setState({ image: file })

    };


    render() {

        const { students, name, search, removeModal, newModal, selectedItem, selectedEvaItem, degreeValue, surname, bDay, image, no } = this.state

        const filteredData = students.filter(item =>
            item.first_name.toLowerCase().includes(search.toLowerCase()) ||
            item.last_name.toLowerCase().includes(search.toLowerCase()) ||
            item.no.toLowerCase().includes(search.toLowerCase())
        );

        return (
            <Context title="Öğrenciler" context={
                <View style={{ minHeight: height }}>
                    <div className="container mt-5">
                        <Form>
                            <Row>
                                <Col xs={12} md={11} className="mb-2">
                                    <Form.Control
                                        type="text"
                                        placeholder="Arama..."
                                        onChange={(e) => {
                                            this.setState({ search: e.target.value })
                                        }}
                                        value={search}
                                        className="search-input"
                                    />
                                </Col>
                                <Col xs={3} md={1} className="mb-2">
                                    <Button className="w-100" onClick={() => this.setState({ newModal: true })}>Oluştur</Button>
                                </Col>
                            </Row>
                        </Form>


                        <Table striped bordered hover className="mt-3">
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th className="name-column">Öğrenci Adı</th>
                                    <th>Detay</th>
                                    <th>Sil</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.no}</td>
                                        <td className="name-column">{item.first_name + ' ' + item.last_name}</td>
                                        <td>
                                            <Link to={'/student/'+item.id}>
                                                <Button variant="info" >Detay</Button>
                                            </Link>
                                        </td>
                                        <td>
                                            <Button variant="danger" onClick={() => {
                                                this.setState({ selectedItem: { ...item }, removeModal: true })
                                            }}>Sil</Button>
                                        </td>
                                    </tr>

                                ))}
                            </tbody>
                        </Table>

                    </div>
                    <Modal show={removeModal} onHide={() => this.setState({ removeModal: false, selectedItem: null })}>
                        <Modal.Header closeButton>
                            <Modal.Title>Silmek İstediğinizden Emin Misiniz?</Modal.Title>
                        </Modal.Header>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.setState({ removeModal: false, selectedItem: null })}>
                                Hayır
                            </Button>
                            <Button variant="danger" onClick={() => this.remove()}>
                                Evet
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={newModal} onHide={() => this.setState({ newModal: false, selectedItem: null, })}>
                        <Modal.Header closeButton>
                            <Modal.Title>Öğrencii Ekle</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <View style={{ width: '100', alignItems: 'center', justifyContent: 'center' }}>
                                <div className="rounded-circle border border-primary p-3 m-3" style={{ height: 150, width: 150 }}>
                                    <TouchableOpacity style={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }} onPress={() => {
                                        this._imageFileDialog.click()
                                    }}>
                                        {
                                            image ?
                                                <Image source={{ uri: URL.createObjectURL(image) }} style={{ width: '100%', height: '100%' }} resizeMode='contain' resizeMethod='scale' />
                                                : <Text>Resim Seç</Text>
                                        }
                                    </TouchableOpacity>
                                </div>
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={this.handleImageSelect}
                                    style={{ display: 'none' }}
                                    ref={(node) => this._imageFileDialog = node}
                                />
                            </View>
                            <Row>
                                <Col xs={12} md={6} className="mb-2">
                                    <Form.Control
                                        type="text"
                                        placeholder="Ad"
                                        value={name}
                                        onChange={(e) => {
                                            this.setState({ name: e.target.value })
                                        }}
                                    />
                                </Col>
                                <Col xs={12} md={6} className="mb-2">
                                    <Form.Control
                                        type="text"
                                        placeholder="Soyad"
                                        value={surname}
                                        onChange={(e) => {
                                            this.setState({ surname: e.target.value })
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6} className="mb-2">
                                    <Form.Control
                                        type="text"
                                        placeholder="Öğrenci No"
                                        value={no}
                                        onChange={(e) => {
                                            this.setState({ no: e.target.value })
                                        }}
                                    />
                                </Col>
                                <Col xs={12} md={6} className="mb-2">
                                    <Form.Control
                                        type="date"
                                        placeholder="Doğum Tarihi"
                                        value={bDay}
                                        onChange={(e) => {
                                            this.setState({ bDay: e.target.value })
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.setState({ newModal: false, selectedItem: null })}>İptal</Button>
                            <Button variant="primary" onClick={() => this.create()}>Kaydet</Button>
                        </Modal.Footer>
                    </Modal>


                </View>
            } />
        )
    }
}

const mapStateToProps = (state) => ({
    students: state.Students.students
})

const mapDispatchToProps = {
    getEvaluations,
    getStudents,
    createStudent,
    updateStudent,
    removeStudents
}

export default connect(mapStateToProps, mapDispatchToProps)(Student)
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux'
import store from './src/redux/store'
import MyRouter from './MyRouter';



function App() {
  return (
    <Provider store={store}>
      <MyRouter/>
    </Provider>
  );
}



export default App;

import axios from 'axios'
import { JOBS,CREATE_JOB,UPDATE_JOB,REMOVE_JOB } from './types'
import { MAIN_URL, tokenConfig,tokenConfigMultiPart } from './host'

export const getJobs = (e) => (dispatch, getState) => {

    let config = tokenConfig(getState)


    axios.get(MAIN_URL + '/jobs/job/', config)
        .then(res => {
            dispatch({
                type: JOBS,
                payload: res.data
            })
            e(res.data)
        })
}

export const getJob = (id,e) => (dispatch, getState) => {

    let config = tokenConfig(getState)


    axios.get(MAIN_URL + '/jobs/job/'+id+'/', config)
        .then(res => {
            e(res.data)
        })
}

export const createJob = (data,e) => (dispatch, getState) => {

    let config=tokenConfigMultiPart(getState)

    axios.post(MAIN_URL + '/jobs/job/',data, config)
        .then(res => {
            dispatch({
                type: CREATE_JOB,
                payload: res.data
            })

            e(res.data)
        })
}


export const updateJob = (id,data,e) => (dispatch, getState) => {

    let config=tokenConfig(getState)


    axios.put(MAIN_URL + '/jobs/job/'+id+'/',data, config)
        .then(res => {
            dispatch({
                type: UPDATE_JOB,
                payload: res.data
            })

            e(res.data)
        })
}

export const removeJob = (id,e) => (dispatch, getState) => {

    let config = tokenConfig(getState)


    axios.delete(MAIN_URL + '/jobs/job/'+id+'/', config)
        .then(res => {
            dispatch({
                type: REMOVE_JOB,
                payload: id
            })

            e(res.data)
        })
}

/*
export const BASE_URL = 'http://127.0.0.1:8000'
export const IMAGE_URL = 'http://127.0.0.1:8000'
export const MAIN_URL = 'http://127.0.0.1:8000'
export const SOCKET_URL = 'http://127.0.0.1:3000'
export const MEDIA_URL = 'http://127.0.0.1:8000/media/'

*/

export const BASE_URL = 'https://ulassoftapp.com/yapay'
export const IMAGE_URL = 'https://ulassoftapp.com/yapay'
export const MAIN_URL = 'https://ulassoftapp.com/yapay'
export const MEDIA_URL = 'https://ulassoftapp.com'
import Cookies from 'js-cookie';


export const tokenConfig = getState => {
    const token =  Cookies.get('token');

    const csrf = Cookies.get('csrf');
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken':csrf
        }
    }

    if (token) {
        config.headers['Authorization'] = `Token ${token}`
    }

    return config
}

export const tokenConfigMultiPart = getState => {
    const token = getState().User.auth.token
    const csrf = getState().User.csrf
    const config = {
        headers: {
            'accept':'application/json',
            'content-type': 'multipart/form-data',
            'X-CSRFToken':csrf
        }
    }

    if (token) {
        config.headers['Authorization'] = `Token ${token}`
    }

    return config
}

export const getUserID = getState => {
    const id = getState().User.user.id

    return id
}
import axios from 'axios'
import { STUDENTS,CREATE_STUDENT,UPDATE_STUDENT,REMOVE_STUDENT } from './types'
import { MAIN_URL, tokenConfig,tokenConfigMultiPart } from './host'

export const getStudents = (e) => (dispatch, getState) => {

    let config = tokenConfig(getState)


    axios.get(MAIN_URL + '/students/', config)
        .then(res => {
            dispatch({
                type: STUDENTS,
                payload: res.data
            })
        })
}

export const getStudent = (id,e) => (dispatch, getState) => {

    let config = tokenConfig(getState)


    axios.get(MAIN_URL + '/students/'+id+'/', config)
        .then(res => {
            e(res.data)
        })
}

export const createStudent = (data,e) => (dispatch, getState) => {

    let config=tokenConfigMultiPart(getState)

    axios.post(MAIN_URL + '/students/',data, config)
        .then(res => {
            dispatch({
                type: CREATE_STUDENT,
                payload: res.data
            })

            e(res.data)
        })
}


export const updateStudent = (id,data,e) => (dispatch, getState) => {

    let config=tokenConfigMultiPart(getState)


    axios.put(MAIN_URL + '/students/'+id+'/',data, config)
        .then(res => {
            dispatch({
                type: UPDATE_STUDENT,
                payload: res.data
            })

            e(res.data)
        })
}

export const removeStudents = (id,e) => (dispatch, getState) => {

    let config = tokenConfig(getState)


    axios.delete(MAIN_URL + '/students/'+id+'/', config)
        .then(res => {
            dispatch({
                type: REMOVE_STUDENT,
                payload: id
            })

            e(res.data)
        })
}


export const getStudentJob = (data,e) => (dispatch, getState) => {

    let config = tokenConfig(getState)


    axios.post(MAIN_URL + '/jobs/result/',data, config)
        .then(res => {
            e(res.data)
        })
}
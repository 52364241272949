import { STUDENTS,CREATE_STUDENT,UPDATE_STUDENT,REMOVE_STUDENT } from '../actions/types';

const initialState = {
    students: [],
};


export default function (state = initialState, action) {
    switch (action.type) {
        case STUDENTS:
            return {
                ...state,
                students: action.payload,
            };
        case CREATE_STUDENT:
            return {
                ...state,
                students: [...state.students, action.payload],
            };
        case UPDATE_STUDENT:
            const updatedStudent = action.payload;
            const updatedStudents = state.students.map((student) =>
                student.id === updatedStudent.id ? updatedStudent : student
            );
            return {
                ...state,
                students: updatedStudents,
            };
        case REMOVE_STUDENT:
            const removedStudentId = action.payload;
            const remainingStudents = state.students.filter(
                (student) => student.id !== removedStudentId
            );
            return {
                ...state,
                students: remainingStudents,
            };

        default:
            return state;
    }
}

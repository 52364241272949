import axios from 'axios'
import { ABILITIES,CREATE_ABILITY,UPDATE_ABILITY,REMOVE_ABILITY } from './types'
import { MAIN_URL, tokenConfig,tokenConfigMultiPart } from './host'

export const getAbilities = (e) => (dispatch, getState) => {

    let config = tokenConfig(getState)


    axios.get(MAIN_URL + '/ability/', config)
        .then(res => {
            dispatch({
                type: ABILITIES,
                payload: res.data
            })
            e(res.data)
        })
}

export const getAbility = (id,e) => (dispatch, getState) => {

    let config = tokenConfig(getState)


    axios.get(MAIN_URL + '/ability/'+id+'/', config)
        .then(res => {
            e(res.data)
        })
}

export const createAbility = (data,e) => (dispatch, getState) => {

    let config=tokenConfigMultiPart(getState)

    axios.post(MAIN_URL + '/ability/',data, config)
        .then(res => {
            dispatch({
                type: CREATE_ABILITY,
                payload: res.data
            })

            e(res.data)
        })
}


export const updateAbility = (id,data,e) => (dispatch, getState) => {

    let config=tokenConfig(getState)


    axios.put(MAIN_URL + '/ability/'+id+'/',data, config)
        .then(res => {
            dispatch({
                type: UPDATE_ABILITY,
                payload: res.data
            })

            e(res.data)
        })
}

export const removeAbility = (id,e) => (dispatch, getState) => {

    let config = tokenConfig(getState)


    axios.delete(MAIN_URL + '/ability/'+id+'/', config)
        .then(res => {
            dispatch({
                type: REMOVE_ABILITY,
                payload: id
            })

            e(id)
        })
}

import { EVALUATIONS,CREATE_EVALUATION,UPDATE_EVALUATION,REMOVE_EVALUATION } from '../actions/types';

const initialState = {
    evaluations: [],
};


export default function (state = initialState, action) {
    switch (action.type) {
        case EVALUATIONS:
            return {
                ...state,
                evaluations: action.payload,
            };
        case CREATE_EVALUATION:
            return {
                ...state,
                evaluations: [...state.evaluations, action.payload],
            };
        case UPDATE_EVALUATION:
            const updatedEvaluation = action.payload;
            const updatedEvaluations = state.evaluations.map((evaluation) =>
                evaluation.id === updatedEvaluation.id ? updatedEvaluation : evaluation
            );
            return {
                ...state,
                evaluations: updatedEvaluations,
            };
        case REMOVE_EVALUATION:
            const removedEvaluationId = action.payload;
            const remainingEvaluations = state.evaluations.filter(
                (evaluation) => evaluation.id !== removedEvaluationId
            );
            return {
                ...state,
                evaluations: remainingEvaluations,
            };

        default:
            return state;
    }
}

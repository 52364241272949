import React, { Component } from "react";
import { View, Text, Dimensions, Image, TouchableOpacity, ScrollView } from "react-native";
import Context from "../context";
import { connect } from "react-redux";
import { getEvaluations } from "../redux/actions/evaluation";
import {getTeacher,createTeacher,updateTeacher,removeTeachers} from "../redux/actions/teachers";
import {  Button, Form, Row, Col, Modal } from "react-bootstrap";
import { MEDIA_URL } from "../redux/actions/host";
import moment from 'moment'
import { getLessons } from '../redux/actions/lesson'
import {passReset} from '../redux/actions/auth'

const { height } = Dimensions.get("screen");

export class TeacherDetail extends Component {
  state = {
    account: null,
    image: "",
    password:'',
    removeModal: false,
    edit: false,
    loading: true,
    startTime: moment(new Date()),
    endTime: null
  };


  componentDidMount() {
    this.props.getLessons()
    const url = window.location.href;
    const id = url.substring(url.lastIndexOf("/") + 1);

    this.props.getTeacher(id, (e) => {
      console.log("e", e);

      let copyObj = e;
      copyObj["loading"] = false;
      copyObj["id"] = id;

      this.setState(copyObj, () => console.log("state", this.state));
    });

  }

  update = () => {
    const { id, account, branch, image, } = this.state;

    var data = new FormData();
    data.append('first_name', account.first_name)
    data.append('last_name', account.last_name)
    data.append('email', account.email)

    if (branch)
      data.append('branch', branch.id)

    if (typeof image !== "string") {
      console.log({
        uri: URL.createObjectURL(image),
        type: "image/jpeg",
        name: `teacher.jpg`,
      });
      data.append("image", image);
    }

    this.props.updateTeacher(id, data, (e) => {
      console.log("updateTeacher", e);
      let copyItem = { ...e }
      copyItem['image'] = null
      this.setState(copyItem, () => alert('Öğretmen Güncellendi!'));
    });
  };

  remove = () => {
    const { id } = this.state;
    this.props.removeTeachers(id, (e) => {
      window.location.href = "/teacher";
    });
  };

  handleImageSelect = (e) => {
    const file = e.target.files[0];
    console.log("file", URL.createObjectURL(file));
    this.setState({ image: file });
  };

  changePass=()=>{
    
    const {password,account}=this.state
    if(password.length<5){
      alert('Şifre minimum 5 karakterden oluşmalıdır!')
      return
    }else{

      this.props.passReset({id:account.id,password},(e)=>{

        if(e.state){
          alert('Şifre güncellendi!')
          this.setState({edit:false,password:''})
          return
        }else{
          alert('Bir hata oluştu. Lütfen tekrar deneyiniz!')
        }

      })

    }

  }

  render() {
    const { image, removeModal, account, branch, edit,password } = this.state;
    const { lessons } = this.props


    return (
      <Context
        title="Öğretmen"
        context={
          <View style={{ minHeight: height }}>
            <div className="container mt-5">
              <Row className="justify-content-md-center">
                <Col md={3}>
                  <TouchableOpacity
                    onPress={() => this._imageFileDialog.click()}
                  >
                    <Image
                      source={{
                        uri: image ? URL.createObjectURL(image) :
                          account?.image?.startsWith("http") ? account?.image : MEDIA_URL + account?.image
                      }}

                      style={{
                        width: 150,
                        height: 150,
                        borderRadius: 75,
                        borderWidth: 1,
                        borderColor: "rgba(0,0,0,0.1)",
                      }}
                    />
                  </TouchableOpacity>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={this.handleImageSelect}
                    style={{ display: "none" }}
                    ref={(node) => (this._imageFileDialog = node)}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={6}>
                  <Form.Group controlId="formFirstName">
                    <Form.Label>İsim</Form.Label>
                    <Form.Control
                      type="text"
                      value={account?.first_name}
                      placeholder="İsim giriniz"
                      onChange={(e) =>
                        this.setState({ account: { ...account, first_name: e.target.value } })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formLastName">
                    <Form.Label>Soyisim</Form.Label>
                    <Form.Control
                      type="text"
                      value={account?.last_name}
                      placeholder="Soyisim giriniz"
                      onChange={(e) =>
                        this.setState({ account: { ...account, last_name: e.target.value } })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={6}>
                  <Form.Group controlId="formSchoolNumber">
                    <Form.Label>Branş</Form.Label>

                    {
                      branch && lessons ?
                        <select className="form-select" value={branch.id} onChange={(e) => {
                          const value = Number.parseInt(e.target.value)
                          this.setState({ branch: value === -1 ? null : lessons.find((item) => item.id === value) })
                        }} >
                          <option value={-1}>Boş</option>
                          {
                            lessons.map((lesson) => {

                              const selectedLesson = branch.id === lesson.id ? true : false
                              console.log('selected', selectedLesson)

                              return (
                                <option value={lesson.id} selected={selectedLesson}>{lesson.name}</option>
                              )
                            })
                          }
                        </select>
                        : null
                    }

                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formNewPass">

                    <Form.Label>Mail Adresi</Form.Label>
                    <Form.Control
                      type="text"
                      value={account?.email}
                      placeholder="Mail Adresi"
                      onChange={(e) =>
                        this.setState({ account: { ...account, email: e.target.value } })
                      }
                    />

                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={12} md={4} className="mb-2">
                  <Button className="w-100" onClick={() => this.update()}>
                    Kaydet
                  </Button>
                </Col>
                <Col xs={12} md={4} className="mb-2">
                  <Button
                    className="w-100"
                    variant="info"
                    onClick={() => this.setState({ edit: true })}
                  >
                    Şifre Sıfırla
                  </Button>
                </Col>
                <Col xs={12} md={4} className="mb-2">
                  <Button
                    className="w-100"
                    variant="danger"
                    onClick={() => this.setState({ removeModal: true })}
                  >
                    Sil
                  </Button>
                </Col>
              </Row>

            </div>



            <Modal
              show={removeModal}
              onHide={() => this.setState({ removeModal: false })}
            >
              <Modal.Header closeButton>
                <Modal.Title>Silmek İstediğinizden Emin Misiniz?</Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => this.setState({ removeModal: false })}
                >
                  Hayır
                </Button>
                <Button variant="danger" onClick={() => this.remove()}>
                  Evet
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={edit} onHide={() => this.setState({ edit: false,})}>
              <Modal.Header closeButton>
                <Modal.Title>Şifre Yenile</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                
                <Row>
                  <Col xs={12} md={12} className="mb-2">
                    <Form.Label>Yeni Şifreyi Gir</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Yeni Şifre Giriniz"
                      value={password}
                      onChange={(e) => {
                        this.setState({ password: e.target.value })
                      }}
                    />
                  </Col>
                 
                </Row>
                
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => this.setState({ edit: false })}>İptal</Button>
                <Button variant="primary" onClick={() => this.changePass()}>Kaydet</Button>
              </Modal.Footer>
            </Modal>
          </View>
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  teachers: state.Teachers.teachers,
  lessons: state.Lessons.lessons

});

const mapDispatchToProps = {
  getEvaluations,
  getTeacher,
  createTeacher,
  updateTeacher,
  removeTeachers,
  getLessons,
  passReset
};

export default connect(mapStateToProps, mapDispatchToProps)(TeacherDetail);

import React, { Component } from "react";
import { View, Text, Dimensions, Image, TouchableOpacity } from "react-native";
import Context from "../context";
import { connect } from "react-redux";
import { getClasses, updateClass, getClass } from "../redux/actions/class";
import { getLessons } from "../redux/actions/lesson";
import { getUsers } from "../redux/actions/user";
import { getStudents } from "../redux/actions/student";
import { Table, Button, Form, Row, Col, Modal, Nav } from "react-bootstrap";
import {getTeachers} from '../redux/actions/teachers'
import { Link } from "react-router-dom";
import "./style.css";

const { height } = Dimensions.get("screen");

export class DetailClass extends Component {
  state = {
    name: "",
    students: [],
    lessons: [],
  };

  componentDidMount() {
    const url = window.location.href;
    const id = url.substring(url.lastIndexOf('/') + 1)

    this.props.getClass(id, (e) => {
      console.log('class', e)
      this.setState(e)
    })

    this.props.getLessons();
    this.props.getUsers();
    this.props.getStudents();
    this.props.getTeachers()
  }

  remove = () => {
    const { selectedItem } = this.state;
    this.props.removeStudent(selectedItem.id, (e) => {
      this.setState({ selectedItem: null, removeModal: false });
    });
  };

  update = () => {

    const data = { ...this.state }

    this.props.updateClass(data.id, data, (e) => {
      this.setState(e)
      console.log('e',e)
      alert('Güncellendi!')
    })

  }

  render() {
    const { name, students, lessons } = this.state;
    const { users,teachers } = this.props;

    console.log("users", users);

    return (
      <Context
        title="Sınıflar"
        context={
          <View style={{ minHeight: height }}>
            <div className="container mt-5">
              <Form>
                <Row>
                  <Col xs={12} md={12} className="mb-2">
                    <Form.Control
                      type="text"
                      placeholder="Sınıf Adı"
                      onChange={(e) => {
                        this.setState({ name: e.target.value });
                      }}
                      value={name}
                    />
                  </Col>
                </Row>
              </Form>

              <View
                style={{
                  flexDirection: "row",
                  marginTop: 20,
                  alignItems: "center",
                }}
              >
                <h5>Dersler</h5>
                <TouchableOpacity
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: 25,
                    backgroundColor: "#2ecc71",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: 10,
                  }}
                  onPress={() => {
                    this.setState({
                      lessons: [
                        ...this.state.lessons,
                        { lesson: null, teacher: null, lessonHour: 1, edit: 'add' },
                      ],
                    });
                  }}
                >
                  <h3 style={{ color: "white" }}>+</h3>
                </TouchableOpacity>
              </View>

              <Table striped bordered hover className="mt-3">
                <thead>
                  <tr>
                    <th>Sıra</th>
                    <th className="name-column">Ders Adı</th>
                    <th style={{ flex: 1 }}>Öğretmen</th>
                    <th style={{ width: 120 }}>Ders Saati</th>
                    <th>Sil</th>
                  </tr>
                </thead>
                <tbody>
                  {lessons.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="name-column">
                        {
                          <select disabled={item.edit && item.edit === 'remove' ? true : false}
                            className="form-select"
                            value={item.lesson?.id}
                            onChange={(e) => {
                              let copyItems = [...lessons];
                              let selectedItem = this.props.lessons.find((les) => les.id === Number.parseInt(e.target.value))
                              copyItems[index].lesson = selectedItem
                              this.setState({ lessons: copyItems }, () =>
                                console.log("state", this.state.lessons)
                              );
                            }}
                          >
                            <option value="">Ders Seç</option>
                            {this.props.lessons.map((lesson, i) => {
                              return (
                                <option value={lesson.id} key={lesson.id}>
                                  {lesson.name}
                                </option>
                              );
                            })}
                          </select>
                        }
                      </td>
                      <td>
                        {
                          <select disabled={item.edit && item.edit === 'remove' ? true : false}
                            className="form-select"
                            value={item.teacher?.id}
                            style={{ width: 200 }}
                            onChange={(e) => {
                              let copyItems = [...lessons];
                              const selectedTeacher = teachers.find((tech) => tech.id === Number.parseInt(e.target.value))
                              copyItems[index].teacher = selectedTeacher
                              if(copyItems[index]['edit']!=='add'){
                                copyItems[index]['edit'] = 'change'
                              }
                              this.setState({ lessons: copyItems }, () =>
                                console.log("state", this.state)
                              );
                            }}
                          >
                            <option value="">Öğretmen Seç</option>
                            {teachers.map((teacher, i) => {
                              return (
                                <option value={teacher.id} key={teacher.id}>
                                  {teacher.account?.first_name + " " + teacher.account?.last_name}
                                </option>
                              );
                            })}
                          </select>
                        }
                      </td>
                      <td>
                        <Form.Control
                          disabled={item.edit && item.edit === 'remove' ? true : false}
                          type="number"
                          placeholder="Ders Saati"
                          onChange={(e) => {
                            let copyItems = [...lessons];
                            copyItems[index].lessonHour = Number.parseInt(
                              e.target.value
                            );
                            if (!item.edit || item.edit !== 'add') {
                              copyItems[index].edit = 'change'
                            }
                            this.setState({ lessons: copyItems }, () =>
                              console.log("state", this.state)
                            );
                          }}
                          className="form-control form-control-sm"
                          style={{ width: 75 }}
                          value={item.lessonHour}
                        />
                      </td>

                      <td>
                        <Button
                          variant="danger"
                          onClick={() => {
                            let copyItems = [...lessons];
                            let removeItem = {
                              id: item.id,
                              lesson: item.lesson,
                              teacher: item.teacher,
                              lessonHour: item.lessonHour
                            }
                            if (!item.edit || item.edit !== 'remove') {
                              removeItem['edit'] = 'remove'
                            }
                            copyItems.splice(index, 1, removeItem);
                            this.setState({ lessons: copyItems });
                          }}
                        >
                          {item.edit && item.edit === 'remove' ? 'Geri Al' : 'Sil'}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <View
                style={{
                  flexDirection: "row",
                  marginTop: 20,
                  alignItems: "center",
                }}
              >
                <h5>Öğrenciler</h5>
                <TouchableOpacity
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: 25,
                    backgroundColor: "#2ecc71",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: 10,
                  }}
                  onPress={() => {
                    this.setState({
                      students: [...this.state.students, { edit: 'add' }],
                    });
                  }}
                >
                  <h3 style={{ color: "white" }}>+</h3>
                </TouchableOpacity>
              </View>

              <Table striped bordered hover className="mt-3">
                <thead>
                  <tr>
                    <th>Sıra</th>
                    <th className="name-column">Öğrenci</th>
                    <th className="name-column">Detay</th>
                    <th>Sil</th>
                  </tr>
                </thead>
                <tbody>
                  {students.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="name-column">
                        {
                          <select disabled={item.edit && item.edit === 'remove' ? true : false}
                            className="form-select"
                            value={item.id}
                            onChange={(e) => {
                              let copyItems = [...students];
                              let copyItem = copyItems[index]
                              let propsStudent = this.props.students.find(
                                (stu) =>
                                  stu.id === Number.parseInt(e.target.value)
                              );
                              let selectedStudent = { ...copyItem, ...propsStudent }
                              if (!item.edit)
                                selectedStudent['edit'] = 'change'

                              copyItems.splice(index, 1, selectedStudent);
                              this.setState({ students: copyItems }, () =>
                                console.log("state", this.state)
                              );
                            }}
                          >
                            <option value="">Öğrenci Seç</option>
                            {this.props.students.map((student, i) => {
                              return (
                                <option value={student.id} key={student.id}>
                                  {student.first_name + " " + student.last_name}
                                </option>
                              );
                            })}
                          </select>
                        }
                      </td>
                      <td>
                        {
                          item.id ?
                            <Link to={'/student/' + item.id}>
                              <Button variant="info" >Detay</Button>
                            </Link> : null
                        }
                      </td>
                      <td>
                        <Button
                          variant="danger"
                          onClick={() => {
                            let copyItems = [...students];
                            let removeItem = copyItems[index]
                            if (!item.edit || item.edit !== 'remove') {
                              removeItem['edit'] = 'remove'
                            } else if (item.edit && item.edit === 'remove') {
                              delete removeItem.edit
                            }
                            copyItems.splice(index, 1, removeItem);
                            this.setState({ students: copyItems }, () => console.log('stae', this.state));
                          }}
                        >
                          {item.edit && item.edit === 'remove' ? 'Geri Al' : 'Sil'}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <Col xs={12} md={12} className="mb-2">
                <Button className="w-100" onClick={() => this.update()}>
                  Kaydet
                </Button>
              </Col>
            </div>
          </View>
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  classes: state.Classes.classes,
  lessons: state.Lessons.lessons,
  users: state.Users.users,
  students: state.Students.students,
  teachers:state.Teachers.teachers
});

const mapDispatchToProps = {
  getClasses,
  getLessons,
  getUsers,
  getStudents,
  updateClass,
  getClass,
  getTeachers
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailClass);

import React, { Component } from 'react'
import {View,Text, Dimensions} from 'react-native'
import Context from '../../context'
import { connect } from 'react-redux'
import {getEvaluations,createEvaluation,updateEvaluation,removeEvaluations} from '../../redux/actions/evaluation'
import LessonDetailPage from './detail'

const {height} = Dimensions.get('screen')

export class LessonDetail extends Component {

  componentDidMount(){
    this.props.getEvaluations()
  }

  create=(name)=>{
    this.props.createEvaluation({
      name
    },(e)=>{
      console.log('oluşturma',e)
    })
  }

  update=(id,name)=>{

    this.props.updateEvaluation(id,{name},(e)=>{
      console.log('güncelleme',e)
    })

  }

  remove=(id)=>{
    this.props.removeEvaluations(id,(e)=>{
      console.log('silme',e)
    })
  }

  render() {

    const {evaluations} = this.props

    return (
     <Context title="Türkçe Dersi" context={
      <View style={{minHeight:height}}>
        <LessonDetailPage />
      </View>
     } /> 
    )
  }
}

const mapStateToProps = (state) => ({
  evaluations:state.Evaluations.evaluations
})

const mapDispatchToProps = {
  getEvaluations,
  createEvaluation,
  updateEvaluation,
  removeEvaluations
}

export default connect(mapStateToProps, mapDispatchToProps)(LessonDetail)
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import {logOut} from '../redux/actions/auth'

export class TopMenu extends Component {
  render() {

    const {user} = this.props

    return (
      <Navbar collapseOnSelect expand="lg" bg="primary" variant="dark">
        <Container>
          <Navbar.Brand href="#home">Logo</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Anasayfa</Nav.Link>
              <Nav.Link href="/about">Hakkında</Nav.Link>
              {/* Diğer menü öğeleri */}
            </Nav>
            <Nav>
              <NavDropdown title={user?.email} alignRight>
                <NavDropdown.Item href="#action/3.1">Profil</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">Ayarlar</NavDropdown.Item>
                <NavDropdown.Item onClick={()=>this.props.logOut()}>Çıkış</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.3">Ayrıntılı Bilgi</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.User.user,
})

const mapDispatchToProps = {
  logOut
}

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu)
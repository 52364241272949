import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Cookies from 'js-cookie';

import { loadUser, login } from './src/redux/actions/auth'

import Home from './src/Home'
import Evaluation from './src/Evaluation'
import Lesson from './src/Lesson'
import LessonDetail from './src/Lesson/Detail'
import Student from './src/Student'
import StudentDetail from './src/Student/detail'
import Teacher from './src/Teacher'
import TeacherDetail from './src/Teacher/detail'
import Classes from './src/Class'
import CreateClass from './src/Class/create'
import DetailClass from './src/Class/detail'
import Score from './src/Score'
import Job from './src/Job'
import LoginPage from './src/Auth'
import { View } from 'react-native';
import Ability from './src/Ability';


function NotFoundPage() {
    // 404 Sayfa içeriği
    return <div>404 Sayfa Bulunamadı</div>;
}

export class MyRouter extends Component {

    state = {
        token: "",
        page: "splash",
    };

    constructor(props) {
        super(props);

        console.log('cookie', this.getUserInformation())
    }

    getUserInformation = () => {
        const tokenCookie = Cookies.get('token');
        console.log('token',tokenCookie)

        if (tokenCookie) {
            this.props.loadUser(tokenCookie)
        } else {
            return null;
        }

    };

    render() {

        const { user } = this.props
        
        return (
            user ?
                <Router>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/home" element={<Home />} />
                        <Route path="/evaluation" element={<Evaluation />} />
                        <Route path="/lesson/:id" element={<LessonDetail />} />
                        <Route path="/lesson" element={<Lesson />} />
                        <Route path="/student" element={<Student />} />
                        <Route path="/student/:id" element={<StudentDetail />} />
                        <Route path="/classes" element={<Classes />} />
                        <Route path="/createClass" element={<CreateClass />} />
                        <Route path="/classes/:id" element={<DetailClass />} />
                        <Route path="/score" element={<Score />} />
                        <Route path="/job" element={<Job />} />
                        <Route path="/teacher" element={<Teacher />} />
                        <Route path="/teacher/:id" element={<TeacherDetail />} />
                        <Route path="/ability" element={<Ability />} />

                        {/* Diğer sayfa yönlendirmeleri */}
                        <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                </Router> :

                <Router>
                    <Routes>
                        <Route path="*" element={<LoginPage />} />
                    </Routes>
                </Router>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.User.auth,
    user: state.User.user,
})

const mapDispatchToProps = {
    loadUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyRouter)
import { TEACHERS,CREATE_TEACHER,UPDATE_TEACHER,REMOVE_TEACHER } from '../actions/types';

const initialState = {
    teachers: [],
};


export default function (state = initialState, action) {
    switch (action.type) {
        case TEACHERS:
            return {
                ...state,
                teachers: action.payload,
            };
        case CREATE_TEACHER:
            return {
                ...state,
                teachers: [...state.teachers, action.payload],
            };
        case UPDATE_TEACHER:
            const updatedTeacher = action.payload;
            const updatedTeachers = state.teachers.map((student) =>
                student.id === updatedTeacher.id ? updatedTeacher : student
            );
            return {
                ...state,
                teachers: updatedTeachers,
            };
        case REMOVE_TEACHER:
            const removedTeacherId = action.payload;
            const remainingTeachers = state.teachers.filter(
                (student) => student.id !== removedTeacherId
            );
            return {
                ...state,
                teachers: remainingTeachers,
            };

        default:
            return state;
    }
}

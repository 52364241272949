import React from 'react'
import { View, Text, Dimensions, Image, TouchableOpacity, ScrollView, } from "react-native";
import { Table, Button, Form, Row, Col, Modal, Nav } from "react-bootstrap";
import Chart from 'react-apexcharts'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { icons } from '../icons';
import * as Progress from 'react-native-progress';

function PDFDetail({
    show = false,
    cancel = () => { },
    grapOption = {},
    grapSeries = [],
    scales = [],
    jobs = [],
    scaleNames = [],
    student={}
}) {

    const downloadPDF = () => {
        const input = document.getElementById('paper');

        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const imgWidth = 210; // A4 boyutunun genişliği (mm)
                const pageHeight = 297; // A4 boyutunun yüksekliği (mm)
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
                let position = 0;

                const pdf = new jsPDF('p', 'mm');
                let pageHeightLeft = pageHeight;

                // Çizim işlemini gerçekleştir
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);

                heightLeft -= pageHeightLeft;

                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }

                pdf.save('download.pdf');
            });
    };



    return (
        <Modal
            show={show}
            onHide={() => cancel()}
            size="lg"
        >
            <Modal.Header closeButton={()=>cancel()}>

            </Modal.Header>
            <Modal.Body>
                <div id="paper">
                    <View style={{ width: '210mm', minHeight: '297mm' }}>
                        <Image source={icons.Logo} style={{ width: 150, height: 75, position: 'absolute', top: 10, right: 50 }} resizeMode='contain' />
                        <View style={{ height: 75, width: '100%', justifyContent: 'center', marginBottom: 20 }}>
                            <Text style={{ fontSize: 25, fontWeight: '500', }}>Devran Koleji Öğrenci Bilgi Sistemi</Text>
                        </View>
                        <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                            <Image source={icons.Boy} style={{ width: 150, height: 150, borderRadius: 75, marginBottom: 10 }} resizeMode='contain' />
                            <Text style={{ fontSize: 18, fontWeight: '500', marginBottom: 10,textTransform:'uppercase' }}>{student?.first_name+ ' ' + student?.last_name}</Text>
                            <Text style={{ fontSize: 15, marginBottom: 20 }}>NO: {student?.no}</Text>

                            <View style={{ width: '190mm', overflow: 'hidden', marginBottom: 20 }}>
                                <Table striped bordered hover>
                                    <tr>
                                        <th>Beceri</th>
                                        <th>Tanım</th>
                                    </tr>
                                    <tbody>
                                        {
                                            scales.map((scale, index) => {
                                                return (
                                                    <tr>
                                                        <td>{scaleNames[index]}</td>
                                                        <td>{scale}</td>
                                                    </tr>
                                                )
                                            })
                                        }


                                    </tbody>
                                </Table>
                            </View>

                            <View style={{ width: '190mm', overflow: 'hidden', }}>
                                <Chart options={grapOption} series={grapSeries} type="bar" width={'100%'} height={300} />
                            </View>

                            <Text style={{ fontSize: 25, fontWeight: '500', marginBottom: 20 }}>MESLEK ÖNERİSİ</Text>

                            <View style={{ width: '100%', alignItems: 'center', justifyContent: 'space-around', flexWrap: 'wrap', flexDirection: 'row' }}>
                                {
                                    jobs.map((job, index) => {
                                        return (
                                            <View style={{ width: 150, height: 180, marginBottom: 20 }}>
                                                <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                                                    <Progress.Circle size={100} progress={job.percentage / 100} color="#2ecc71" borderWidth={2} borderColor="rgba(0,0,0,0.3)" thickness={5} />
                                                    <View style={{ position: 'absolute', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
                                                        <Text style={{ fontSize: 25, color: 'black', fontWeight: '700' }}>%{job.percentage}</Text>
                                                    </View>
                                                </View>
                                                <View style={{ height: 50, alignItems: 'center', justifyContent: 'center' }}>
                                                    <Text style={{ fontSize: 15, color: 'black' }}>{job.name}</Text>

                                                </View>
                                            </View>
                                        )
                                    })
                                }
                            </View>

                        </View>
                    </View>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => cancel()}
                >
                    Kapat
                </Button>
                <Button variant="danger" onClick={() => downloadPDF()}>
                    PDF İndir
                </Button>
            </Modal.Footer>
        </Modal>
    )
}


export default PDFDetail

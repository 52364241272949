import { USERS,CREATE_USER,UPDATE_USER,REMOVE_USER } from '../actions/types';

const initialState = {
    users: [],
};


export default function (state = initialState, action) {
    switch (action.type) {
        case USERS:
            return {
                ...state,
                users: action.payload,
            };
        case CREATE_USER:
            return {
                ...state,
                users: [...state.users, action.payload],
            };
        case UPDATE_USER:
            const updatedUser = action.payload;
            const updatedUsers = state.users.map((user) =>
                user.id === updatedUser.id ? updatedUser : user
            );
            return {
                ...state,
                users: updatedUsers,
            };
        case REMOVE_USER:
            const removedUserId = action.payload;
            const remainingUsers = state.users.filter(
                (user) => user.id !== removedUserId
            );
            return {
                ...state,
                users: remainingUsers,
            };

        default:
            return state;
    }
}

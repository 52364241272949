import React, { Component } from 'react'
import { View, Text, Dimensions } from 'react-native'
import Context from '../context'
import { connect } from 'react-redux'
import { getEvaluations } from '../redux/actions/evaluation'
import { getAbilities, createAbility, updateAbility, removeAbility } from '../redux/actions/ability'
import { Table, Button, Form, Row, Col, Modal, Nav } from 'react-bootstrap';
import AbilityItem from './ability'

const { height } = Dimensions.get('screen')

export class Ability extends Component {

  state = {
    abilities: [],
    ability: '',
    search: '',
    removeModal: false,
    editModal: false,
    selectedItem: null,
    selectedEvaItem: null,
    degreeValue: 1
  }

  componentDidMount() {
    this.props.getEvaluations()
    this.props.getAbilities((e) => {
      console.log('e', e)
      this.setState({ abilities: e })
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.abilities !== this.props.abilities) {
      if (this.props.abilities !== this.state.abilities)
        this.setState({ abilities: this.props.abilities })
    }
  }


  create = (ability) => {
    this.props.createAbility({
      ability
    }, (e) => {
      console.log('oluşturma', e)
      this.setState({ abilities: [...this.state.abilities, e], ability: '' })
    })
  }

  update = () => {
    const { selectedItem, abilities } = this.state
    this.props.updateAbility(selectedItem.id, {
      ability: selectedItem.ability,
      evaluations: selectedItem.evaluations
    }, (e) => {

      let newList = [...abilities]

      const index = newList.findIndex((ability) => ability.id === e.id)
      newList.splice(index, 1, e)

      this.setState({ selectedItem: null, editModal: false, abilities: newList })
    })

  }

  remove = () => {
    const { selectedItem,abilities } = this.state
    this.props.removeAbility(selectedItem.id, (e) => {
      this.setState({ selectedItem: null, removeModal: false, abilities:[...abilities.filter((ability)=>ability.id!==e)]})
    })
  }

  render() {

    const { evaluations } = this.props
    const { abilities, ability, search, removeModal, editModal, selectedItem, selectedEvaItem, degreeValue } = this.state

    const filteredData = abilities.filter(item =>
      item.ability.toLowerCase().includes(search.toLowerCase())
    );

    return (
      <Context title="Beceriler" context={
        <View style={{ minHeight: height }}>
          <div className="container mt-5">
            <Form>
              <Row>
                <Col xs={12} md={8} className="mb-2">
                  <Form.Control
                    type="text"
                    placeholder="Beceri Adı"
                    value={ability}
                    onChange={(e) => {
                      this.setState({ ability: e.target.value })
                    }}
                  />
                </Col>
                <Col xs={12} md={4} className="mb-2">
                  <Button className="w-100" onClick={() => this.create(ability)}>Oluştur</Button>
                </Col>
              </Row>
            </Form>
            <Row className="mb-3" style={{ marginTop: 20 }}>
              <Col xs={12} className="text-right">
                <Form.Control
                  type="text"
                  placeholder="Arama..."
                  onChange={(e) => {
                    this.setState({ search: e.target.value })
                  }}
                  value={search}
                  className="search-input"
                />
              </Col>
            </Row>

            <Table striped bordered hover className="mt-3">
              <thead>
                <tr>
                  <th>Sıra</th>
                  <th className="ability-column">Beceri Adı</th>
                  <th>Değer Sayısı</th>
                  <th>Düzenle</th>
                  <th>Sil</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="ability-column">{item.ability}</td>
                    <td>{item.evaluations.length}</td>
                    <td>
                      <Button variant="info" onClick={() => {
                        this.setState({ selectedItem: { ...item }, editModal: true })
                      }}>Düzenle</Button>
                    </td>
                    <td>
                      <Button variant="danger" onClick={() => {
                        this.setState({ selectedItem: { ...item }, removeModal: true })
                      }}>Sil</Button>
                    </td>
                  </tr>

                ))}
              </tbody>
            </Table>

          </div>
          <Modal show={removeModal} onHide={() => this.setState({ removeModal: false, selectedItem: null })}>
            <Modal.Header closeButton>
              <Modal.Title>Silmek İstediğinizden Emin Misiniz?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.setState({ removeModal: false, selectedItem: null })}>
                Hayır
              </Button>
              <Button variant="danger" onClick={() => this.remove()}>
                Evet
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={editModal} onHide={() => this.setState({ editModal: false, selectedItem: null, })}>
            <Modal.Header closeButton>
              <Modal.Title>Beceriyi Düzenle</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Control
                type="text"
                value={selectedItem?.ability}
                onChange={(e) => {
                  this.setState({ selectedItem: { ...selectedItem, ability: e.target.value } }, () => console.log('state', this.state))
                }}
              />

              {
                selectedItem ?
                  <select className="form-select" value={selectedEvaItem?.id} onChange={(e) => {
                    this.setState({ selectedEvaItem: evaluations.find((eva) => eva.id === Number.parseInt(e.target.value)) })
                  }} style={{ marginTop: 20, marginBottom: 20 }}>
                    <option value="">Değerlendirme Ekle</option>
                    {
                      evaluations.filter((eva) => selectedItem.evaluations.findIndex((item) => item.id === eva.id) === -1).map((item) => {
                        return (
                          <option value={item.id} key={item.id}>{item.name}</option>
                        )
                      })
                    }
                  </select> : null
              }

              {
                selectedEvaItem ?
                  <Button style={{ marginBottom: 20 }} className="w-100" onClick={() => {
                    this.setState({
                      selectedItem: {
                        ...selectedItem,
                        evaluations: [...selectedItem.evaluations, selectedEvaItem]
                      },
                      selectedEvaItem: null
                    }, () => console.log('state', this.state))
                  }}>Ekle</Button>
                  : null
              }
              <h6>Eklenen Değerlendirmeler</h6>

              <Table striped bordered hover className="mt-3">
                <thead>
                  <tr>
                    <th className="ability-column">Değerlendirme Adı</th>
                    <th>Sil</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    selectedItem?.evaluations?.filter((filt) => filt.edit !== "remove").map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="ability-column">{item?.name}</td>


                          <td>
                            <Button variant="danger" onClick={() => {

                              let copyList = [...selectedItem?.evaluations]

                              this.setState({
                                selectedItem: {
                                  ...selectedItem,
                                  evaluations: copyList.filter((cpItem) => cpItem.id !== item.id)
                                }
                              }, () => console.log('state', this.state))
                            }}>Sil</Button>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.setState({ editModal: false, selectedItem: null })}>İptal</Button>
              <Button variant="primary" onClick={() => this.update()}>Kaydet</Button>
            </Modal.Footer>
          </Modal>


        </View>
      } />
    )
  }
}

const mapStateToProps = (state) => ({
  evaluations: state.Evaluations.evaluations,
  abilityies: state.Ability.abilities
})

const mapDispatchToProps = {
  getEvaluations,
  getAbilities,
  createAbility,
  updateAbility,
  removeAbility
}

export default connect(mapStateToProps, mapDispatchToProps)(Ability)
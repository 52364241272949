import React, { useState } from 'react';
import { Button, Row, Col, ListGroup, Modal } from 'react-bootstrap';

function LessonDetailPage() {
  const defaultItems = ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5'];
  const [leftItems, setLeftItems] = useState(defaultItems);
  const [rightItems, setRightItems] = useState([]);
  const [selectedLeft, setSelectedLeft] = useState([]);
  const [selectedRight, setSelectedRight] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleSelectLeft = (item) => {
    setSelectedLeft(
      selectedLeft.includes(item) 
      ? selectedLeft.filter(i => i !== item) 
      : [...selectedLeft, item]
    );
  };

  const handleSelectRight = (item) => {
    setSelectedRight(
      selectedRight.includes(item) 
      ? selectedRight.filter(i => i !== item) 
      : [...selectedRight, item]
    );
  };

  const moveToLeft = () => {
    setLeftItems([...leftItems, ...selectedRight]);
    setRightItems(rightItems.filter(item => !selectedRight.includes(item)));
    setSelectedRight([]);
  };

  const moveToRight = () => {
    setRightItems([...rightItems, ...selectedLeft]);
    setLeftItems(leftItems.filter(item => !selectedLeft.includes(item)));
    setSelectedLeft([]);
  };

  const saveChanges = () => {
    setShowModal(true);
  };

  return (
    <div className="container py-3">
      <p>Ders için değerlendirme seç.</p>
      <Row>
        <Col md={5}>
          <h4>Değerlendirmeler</h4>
          <ListGroup>
            {leftItems.map(item => (
              <ListGroup.Item 
                key={item} 
                active={selectedLeft.includes(item)}
                onClick={() => handleSelectLeft(item)}
              >
                {item}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>

        <Col md={2} className="d-flex flex-column align-items-center justify-content-center">
          <Button variant="primary" className="mb-2" onClick={moveToRight}>Ekle &gt;</Button>
          <Button variant="secondary" onClick={moveToLeft}>&lt; Çıkart</Button>
        </Col>

        <Col md={5}>
          <h4>Seçilen Değerlendirmeler</h4>
          <ListGroup>
            {rightItems.map(item => (
              <ListGroup.Item 
                key={item} 
                active={selectedRight.includes(item)}
                onClick={() => handleSelectRight(item)}
              >
                {item}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>

      <Button className="mt-3" onClick={saveChanges}>Kaydet</Button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Kayıt Başarılı</Modal.Title>
        </Modal.Header>
        <Modal.Body>Kayıt işlemi gerçekleştirildi.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default LessonDetailPage;

import axios from 'axios'
import { AUTH_ERROR, LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, LOADING, LOADED, CSRF, ACCOUNT_UPDATE, APP_STATE } from './types'
import { MAIN_URL, tokenConfig } from './host'

export const getCSRF = () => dispatch => {
  axios.get(MAIN_URL + '/user/csrf/')
    .then(res => {
      dispatch({
        type: CSRF,
        payload: res.data.csrf
      })
    })
}

export const getAppState = () => dispatch => {
  axios.get(MAIN_URL + '/user/appState/')
    .then(res => {
      console.log('install appstate', res.data)
      dispatch({
        type: APP_STATE,
        payload: res.data
      })
    })
}


export const loadUser = (token) => (dispatch, getState) => {
  dispatch({ type: LOADING })

  let config = tokenConfig(getState)
  if (token) {
    config.headers['Authorization'] = `Token ${token}`
  }

  console.log('auth', config)

  axios.get(MAIN_URL + '/user/', config)
    .then(res => {
      const widthToken = { user: { ...res.data }, token: token }
      dispatch({
        type: LOGIN_SUCCESS,
        payload: widthToken
      })
      dispatch({ type: LOADED })
      console.log('loadUser', res.data)
    })
    .catch(err => {
      console.log("Login Error", err);
      dispatch({
        type: AUTH_ERROR,
      })
      dispatch({ type: LOADED })
    })

}

export const login = (u, p, result = () => { }) => dispatch => {

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }
  dispatch({ type: LOADING })

  const body = JSON.stringify({ username: u, password: p })
  axios.post(MAIN_URL + '/user/login/', body, config)
    .then(res => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data
      })

      dispatch({ type: LOADED })
      result(true)
    })
    .catch(err => {
      console.log(err)
      result(false)
      dispatch({ type: LOADED })
      dispatch({
        type: LOGIN_FAIL,
      })
    })

}


export const register = (data, request) => dispatch => {

  dispatch({ type: LOADING })
  console.log('data', data)
  const header = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }

  fetch(MAIN_URL + '/user/register/', {
    method: 'POST',
    body: data,
  })
    .then((response) => response.json())
    .then((json) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: json
      })
      //console.log('res', json)
      dispatch({ type: LOADED })
      request(json)
    })
    .catch((error) => {
      dispatch({ type: LOGIN_FAIL })
      dispatch({ type: LOADED })
      request(false)
    })

}

export const update = (data, request) => (dispatch, getState) => {

  dispatch({ type: LOADING })
  console.log('data', data)
  const token = getState().User.auth.token


  fetch(MAIN_URL + '/user/accountUpdate/', {
    method: 'POST',
    body: data,
    headers: {
      'Authorization': `Token ${token}`
    }
  })
    .then((response) => response.json())
    .then((json) => {
      dispatch({
        type: ACCOUNT_UPDATE,
        payload: json
      })
      //console.log('res', json)
      dispatch({ type: LOADED })
      request(json)
    })
    .catch((error) => {
      request(false)
    })

}

export const logOut = () => (dispatch, getState) => {

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const token = getState().User.auth.token

  if (token) {
    config.headers['Authorization'] = `Token ${token}`
  }

  axios.post(MAIN_URL + '/user/logout/', null, config)
    .then(res => {

      dispatch({
        type: LOGOUT,
      })

    })
}


export const passReset = (data, request) => (dispatch, getState) => {

  let config = tokenConfig(getState)

  axios.post(MAIN_URL + '/user/passwordReset/', data, config)
    .then(res => {

      request(res.data)

    })

}

export const updatePushToken = (token) => (dispatch, getState) => {
  axios.post(MAIN_URL + '/user/pushTokenUpdate/', {
    token
  }, tokenConfig(getState))
    .then(res => {
      console.log('updatePustToken', res.data)

    })
    .catch(err => {
      console.log(err)

    })

}


export const deleteAccount = (e) => (dispatch, getState) => {
  axios.post(MAIN_URL + '/user/deleteAccount/', {
  }, tokenConfig(getState))
    .then(res => {
      console.log('deleteAccount', res.data)
      e(res.data)
    })
    .catch(err => {
      console.log(err)

    })

}

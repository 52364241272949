import React, { Component } from 'react'
import { View, Text, Dimensions, Image, TouchableOpacity } from 'react-native'
import Context from '../context'
import { connect } from 'react-redux'
import { getEvaluations } from '../redux/actions/evaluation'
import { getTeachers, createTeacher, updateTeacher, removeTeachers } from '../redux/actions/teachers'
import { getLessons } from '../redux/actions/lesson'
import { Table, Button, Form, Row, Col, Modal, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { icons } from '../icons'
import * as XLSX from 'xlsx';


const { height } = Dimensions.get('screen')

export class Teacher extends Component {

    state = {
        teachers: [],
        name: '',
        surname: '',
        branch: null,
        image: null,
        password: '',
        email: '',
        search: '',
        removeModal: false,
        newModal: false,
        selectedItem: null,
        selectedEvaItem: null,
        degreeValue: 1,
        allImport:[],
        allImportModal:false,

    }

    componentDidMount() {
        this.props.getEvaluations()
        this.props.getLessons()
        this.props.getTeachers((e) => {
            this.setState({ teachers: e })
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.teachers !== this.props.teachers) {
            if (this.props.teachers !== this.state.teachers)
                this.setState({ teachers: this.props.teachers })
        }
    }

    create = () => {

        const { name, surname, image, email, password, branch } = this.state

        var data = new FormData()
        data.append('first_name', name)
        data.append('last_name', surname)
        data.append('email', email)
        data.append('password', password)

        if (branch)
            data.append('branch', branch)

        if (image) {
            data.append("image", {
                uri: URL.createObjectURL(image),
                type: 'image/jpeg',
                name: `teacher.jpg`
            });
        }


        this.props.createTeacher(data, (e) => {
            console.log('oluşturma', e)
            alert('Öğretmen Oluşturuldu!')
            this.setState({ teachers: [...this.state.teachers, e], name: '', surname: '', email: '', password: '', branch: null, image: null, newModal: false })
        })
    }

    update = (id, name) => {
        const { selectedItem } = this.state
        console.log('selectedItems', selectedItem.evaluations)
        this.props.updateTeacher(selectedItem.id, {
            name: selectedItem.name,
            evaluations: selectedItem.evaluations
        }, (e) => {
            this.setState({ selectedItem: null, newModal: false, })
        })

    }

    remove = () => {
        const { selectedItem } = this.state
        this.props.removeTeachers(selectedItem.id, (e) => {
            this.setState({ selectedItem: null, removeModal: false })
        })
    }

    handleImageSelect = (e) => {
        const file = e.target.files[0];
        this.setState({ image: file })

    };

    handleExcelFile=(e)=>{
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
          const binaryString = event.target.result;
          const workbook = XLSX.read(binaryString, { type: 'binary' });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const data = XLSX.utils.sheet_to_json(worksheet);
          this.setState({allImport:data,allImportModal:true},()=>console.log(this.state.allImport))
        };
        reader.readAsBinaryString(file);
    
    }


    render() {

        const { teachers, name, search, removeModal, newModal, surname, password, image, branch, email,allImport,allImportModal } = this.state
        const { lessons } = this.props

        const filteredData = teachers.filter(item =>
            item?.account.first_name.toLowerCase().includes(search.toLowerCase()) ||
            item?.account.last_name.toLowerCase().includes(search.toLowerCase()) ||
            item?.branch?.name.toLowerCase().includes(search.toLowerCase())
        );

        return (
            <Context title="Öğretmenler"
                right={
                    <TouchableOpacity style={{ marginRight: 50 }} onPress={()=>this._excelFileDialog.click()}>
                        <Image source={icons.Excel} style={{ width: 35, height: 35 }} />
                    </TouchableOpacity>
                }
                context={
                    <View style={{ minHeight: height }}>
                        <input type="file" accept=".xlsx, .xls" onChange={this.handleExcelFile} ref={(node)=>this._excelFileDialog=node} hidden/>

                        <div className="container mt-5">
                            <Form>
                                <Row>
                                    <Col xs={12} md={11} className="mb-2">
                                        <Form.Control
                                            type="text"
                                            placeholder="Arama..."
                                            onChange={(e) => {
                                                this.setState({ search: e.target.value })
                                            }}
                                            value={search}
                                            className="search-input"
                                        />
                                    </Col>
                                    <Col xs={3} md={1} className="mb-2">
                                        <Button className="w-100" onClick={() => this.setState({ newModal: true })}>Oluştur</Button>
                                    </Col>
                                </Row>
                            </Form>


                            <Table striped bordered hover className="mt-3">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th className="name-column">Öğretmen Adı</th>
                                        <th>Branş</th>
                                        <th>Detay</th>
                                        <th>Sil</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.id}</td>
                                            <td className="name-column">{item?.account?.first_name + ' ' + item?.account?.last_name}</td>
                                            <td>{item?.branch?.name}</td>
                                            <td>
                                                <Link to={'/teacher/' + item.id}>
                                                    <Button variant="info" >Detay</Button>
                                                </Link>
                                            </td>
                                            <td>
                                                <Button variant="danger" onClick={() => {
                                                    this.setState({ selectedItem: { ...item }, removeModal: true })
                                                }}>Sil</Button>
                                            </td>
                                        </tr>

                                    ))}
                                </tbody>
                            </Table>

                        </div>
                        <Modal show={removeModal} onHide={() => this.setState({ removeModal: false, selectedItem: null })}>
                            <Modal.Header closeButton>
                                <Modal.Title>Silmek İstediğinizden Emin Misiniz?</Modal.Title>
                            </Modal.Header>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => this.setState({ removeModal: false, selectedItem: null })}>
                                    Hayır
                                </Button>
                                <Button variant="danger" onClick={() => this.remove()}>
                                    Evet
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={newModal} onHide={() => this.setState({ newModal: false, selectedItem: null, })}>
                            <Modal.Header closeButton>
                                <Modal.Title>Öğretmeni Ekle</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <View style={{ width: '100', alignItems: 'center', justifyContent: 'center' }}>
                                    <div className="rounded-circle border border-primary p-3 m-3" style={{ height: 150, width: 150 }}>
                                        <TouchableOpacity style={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }} onPress={() => {
                                            this._imageFileDialog.click()
                                        }}>
                                            {
                                                image ?
                                                    <Image source={{ uri: URL.createObjectURL(image) }} style={{ width: '100%', height: '100%' }} resizeMode='contain' resizeMethod='scale' />
                                                    : <Text>Resim Seç</Text>
                                            }
                                        </TouchableOpacity>
                                    </div>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={this.handleImageSelect}
                                        style={{ display: 'none' }}
                                        ref={(node) => this._imageFileDialog = node}
                                    />
                                </View>
                                <Row>
                                    <Col xs={12} md={6} className="mb-2">
                                        <Form.Control
                                            type="text"
                                            placeholder="Ad"
                                            value={name}
                                            onChange={(e) => {
                                                this.setState({ name: e.target.value })
                                            }}
                                        />
                                    </Col>
                                    <Col xs={12} md={6} className="mb-2">
                                        <Form.Control
                                            type="text"
                                            placeholder="Soyad"
                                            value={surname}
                                            onChange={(e) => {
                                                this.setState({ surname: e.target.value })
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12} className="mb-2">
                                        <Form.Control
                                            type="text"
                                            placeholder="Mail"
                                            value={email}
                                            onChange={(e) => {
                                                this.setState({ email: e.target.value })
                                            }}
                                        />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col xs={12} md={6} className="mb-2">
                                        <select className="form-select" value={branch} onChange={(e) => {
                                            const value = Number.parseInt(e.target.value)
                                            this.setState({ branch: value === -1 ? null : value })
                                        }} >
                                            <option value={-1}>Boş</option>
                                            {
                                                lessons.map((lesson) => {
                                                    return (
                                                        <option value={lesson.id}>{lesson.name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </Col>
                                    <Col xs={12} md={6} className="mb-2">
                                        <Form.Control
                                            type="password"
                                            placeholder="Şifre"
                                            value={password}
                                            onChange={(e) => {
                                                this.setState({ password: e.target.value })
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => this.setState({ newModal: false, selectedItem: null })}>İptal</Button>
                                <Button variant="primary" onClick={() => this.create()}>Kaydet</Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal size='lg' show={allImportModal} onHide={() => this.setState({ allImportModal: false,allImport:[] })}>
                            <Modal.Header closeButton>
                                <Modal.Title>Toplu Öğretmen Aktarımı</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Text style={{textAlign:'right'}}>Toplam Aktarılacak Öğretmen Sayısı : {allImport.length}</Text>
                                <Table striped bordered hover className="mt-3">
                                    <thead>
                                        <tr>
                                            <td>Öğretmen Adı</td>
                                            <td>Öğretmen Soyadı</td>
                                            <td>Öğretmen Email</td>
                                            <td>Öğretmen Branş</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            allImport.map((teacher)=>{
                                                return(
                                                    <tr>
                                                        <td>{teacher.ogretmen_adi}</td>
                                                        <td>{teacher.ogretmen_soyadi}</td>
                                                        <td>{teacher.ogretmen_mail}</td>
                                                        <td>{teacher.ogretmen_brans}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => this.setState({ allImportModal: false,allImport:[] })}>İptal</Button>
                                <Button variant="primary" onClick={() => this.create()}>Aktar</Button>
                            </Modal.Footer>
                        </Modal>
                    </View>
                } />
        )
    }
}

const mapStateToProps = (state) => ({
    teachers: state.Teachers.teachers,
    lessons: state.Lessons.lessons
})

const mapDispatchToProps = {
    getEvaluations,
    getTeachers,
    createTeacher,
    updateTeacher,
    removeTeachers,
    getLessons
}

export default connect(mapStateToProps, mapDispatchToProps)(Teacher)
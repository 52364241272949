import axios from 'axios'
import { CLASSES,CREATE_CLASS,UPDATE_CLASS,REMOVE_CLASS } from './types'
import { MAIN_URL, tokenConfig,tokenConfigMultiPart } from './host'

export const getClasses = (e) => (dispatch, getState) => {

    let config = tokenConfig(getState)


    axios.get(MAIN_URL + '/classes/', config)
        .then(res => {
            dispatch({
                type: CLASSES,
                payload: res.data
            })
        })
}

export const getClass = (id,e) => (dispatch, getState) => {

    let config = tokenConfig(getState)


    axios.get(MAIN_URL + '/classes/'+id+'/', config)
        .then(res => {
            e(res.data)
        })
}

export const createClass = (data,e) => (dispatch, getState) => {

    let config=tokenConfig(getState)

    axios.post(MAIN_URL + '/classes/',data, config)
        .then(res => {
            dispatch({
                type: CREATE_CLASS,
                payload: res.data
            })

            e(res.data)
        })
}


export const updateClass = (id,data,e) => (dispatch, getState) => {

    let config=tokenConfig(getState)


    axios.put(MAIN_URL + '/classes/'+id+'/',data, config)
        .then(res => {
            dispatch({
                type: UPDATE_CLASS,
                payload: res.data
            })

            e(res.data)
        })
}

export const removeClasss = (id,e) => (dispatch, getState) => {

    let config = tokenConfig(getState)


    axios.delete(MAIN_URL + '/classes/'+id+'/', config)
        .then(res => {
            dispatch({
                type: REMOVE_CLASS,
                payload: id
            })

            e(res.data)
        })
}

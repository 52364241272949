import React from 'react';

function Footer() {
  return (
    <footer style={styles.footer}>
      <p style={styles.text}>&copy; {new Date().getFullYear()} Şirketinizin Adı. Tüm Hakları Saklıdır.</p>
    </footer>
  );
}

// Footer için CSS-in-JS stili
const styles = {
  footer: {
    width: '100%',
    background: '#333', // Footer arkaplan rengi
    color: 'white', // Yazı rengi
    display: 'flex',
    justifyContent: 'center', // İçeriği ortalamak için
    alignItems: 'center',
    padding: '10px 0', // Üst ve alt boşluk
  },
  text: {
    margin: 0, // Varsayılan kenar boşluğunu sıfırlar
  }
};

export default Footer;

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import {thunk} from 'redux-thunk';

const middleware = [thunk];

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware)=>{
    return [...getDefaultMiddleware(), ...middleware]
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;

import React, { Component } from 'react'
import { View, Text, Dimensions, Image, TouchableOpacity } from 'react-native'
import Context from '../context'
import { connect } from 'react-redux'
import { getClasses,removeClasss } from '../redux/actions/class'
import { Table, Button, Form, Row, Col, Modal, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const { height } = Dimensions.get('screen')

export class Classes extends Component {

    state = {
        search: '',
        removeModal: false,
        selectedItem: null
    }

    componentDidMount() {
        this.props.getClasses()
    }


    remove = () => {

        const {selectedItem} = this.state
        
        this.props.removeClasss(selectedItem.id,(e)=>{
            this.setState({ removeModal: false, selectedItem: null })
        })

    }


    render() {

        const { removeModal, search } = this.state
        const { classes } = this.props
        console.log('classes',classes)

        const filteredData = classes.filter(item =>
            item.name.toLowerCase().includes(search.toLowerCase())
        );

        return (
            <Context title="Sınıflar" context={
                <View style={{ minHeight: height }}>
                    <div className="container mt-5">
                        <Form>
                            <Row>
                                <Col xs={12} md={11} className="mb-2">
                                    <Form.Control
                                        type="text"
                                        placeholder="Arama..."
                                        onChange={(e) => {
                                            this.setState({ search: e.target.value })
                                        }}
                                        value={search}
                                        className="search-input"
                                    />
                                </Col>
                                <Col xs={3} md={1} className="mb-2">
                                    <Link to={'/createClass'}>
                                        <Button className="w-100">Oluştur</Button>
                                    </Link>
                                </Col>
                            </Row>
                        </Form>


                        <Table striped bordered hover className="mt-3">
                            <thead>
                                <tr>
                                    <th>Sıra</th>
                                    <th className="name-column">Sınıf Adı</th>
                                    <th className="name-column">Ders Sayısı</th>
                                    <th className="name-column">Öğrenci Sayısı</th>
                                    <th>Detay</th>
                                    <th>Sil</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td className="name-column">{item.name}</td>
                                        <td className="name-column">{item.lessons.length}</td>
                                        <td className="name-column">{item.students.length}</td>
                                        <td>
                                            <Link to={'/classes/' + item.id}>
                                                <Button variant="info" >Detay</Button>
                                            </Link>
                                        </td>
                                        <td>
                                            <Button variant="danger" onClick={() => {
                                                this.setState({ selectedItem: { ...item }, removeModal: true })
                                            }}>Sil</Button>
                                        </td>
                                    </tr>

                                ))}
                            </tbody>
                        </Table>

                    </div>
                    <Modal show={removeModal} onHide={() => this.setState({ removeModal: false, selectedItem: null })}>
                        <Modal.Header closeButton>
                            <Modal.Title>Silmek İstediğinizden Emin Misiniz?</Modal.Title>
                        </Modal.Header>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => this.setState({ removeModal: false, selectedItem: null })}>
                                Hayır
                            </Button>
                            <Button variant="danger" onClick={() => this.remove()}>
                                Evet
                            </Button>
                        </Modal.Footer>
                    </Modal>


                </View>
            } />
        )
    }
}

const mapStateToProps = (state) => ({
    classes: state.Classes.classes
})

const mapDispatchToProps = {
    getClasses,
    removeClasss
}

export default connect(mapStateToProps, mapDispatchToProps)(Classes)
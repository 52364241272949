import { JOBS,CREATE_JOB,UPDATE_JOB,REMOVE_JOB } from '../actions/types';

const initialState = {
    jobs: [],
};


export default function (state = initialState, action) {
    switch (action.type) {
        case JOBS:
            return {
                ...state,
                jobs: action.payload,
            };
        case CREATE_JOB:
            return {
                ...state,
                jobs: [...state.jobs, action.payload],
            };
        case UPDATE_JOB:
            const updatedJob = action.payload;
            const updatedJobs = state.jobs.map((job) =>
                job.id === updatedJob.id ? updatedJob : job
            );
            return {
                ...state,
                jobs: updatedJobs,
            };
        case REMOVE_JOB:
            const removedJobId = action.payload;
            const remainingJobs = state.jobs.filter(
                (job) => job.id !== removedJobId
            );
            return {
                ...state,
                jobs: remainingJobs,
            };

        default:
            return state;
    }
}

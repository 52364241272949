import axios from 'axios'
import { USERS,CREATE_USER,UPDATE_USER,REMOVE_USER } from './types'
import { MAIN_URL, tokenConfig,tokenConfigMultiPart } from './host'

export const getUsers = (e) => (dispatch, getState) => {

    let config = tokenConfig(getState)


    axios.get(MAIN_URL + '/user/accountUpdate/', config)
        .then(res => {
            dispatch({
                type: USERS,
                payload: res.data
            })
        })
}

export const getUser = (id,e) => (dispatch, getState) => {

    let config = tokenConfig(getState)


    axios.get(MAIN_URL + '/user/accountUpdate/'+id+'/', config)
        .then(res => {
            e(res.data)
        })
}

export const createUser = (data,e) => (dispatch, getState) => {

    let config=tokenConfigMultiPart(getState)

    axios.post(MAIN_URL + '/user/accountUpdate/',data, config)
        .then(res => {
            dispatch({
                type: CREATE_USER,
                payload: res.data
            })

            e(res.data)
        })
}


export const updateUser = (id,data,e) => (dispatch, getState) => {

    let config=tokenConfigMultiPart(getState)


    axios.put(MAIN_URL + '/user/accountUpdate/'+id+'/',data, config)
        .then(res => {
            dispatch({
                type: UPDATE_USER,
                payload: res.data
            })

            e(res.data)
        })
}

export const removeUsers = (id,e) => (dispatch, getState) => {

    let config = tokenConfig(getState)


    axios.delete(MAIN_URL + '/user/accountUpdate/'+id+'/', config)
        .then(res => {
            dispatch({
                type: REMOVE_USER,
                payload: id
            })

            e(res.data)
        })
}
